import React, { useState } from 'react';
import { Box, Button, Tabs, Tab, Typography, TextField, useMediaQuery, Grid } from '@mui/material';
import BlogPostAuthorList from 'components/dashboard/Blog/BlogPostAuthorList';
import DashboardBox from 'components/dashboard/Box/DashboardBox';
import FlexBetween from 'components/dashboard/Box/FlexBetween';
import { blogPosts } from 'data/data';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const user = 'John Doe';
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Handle responsive layout using media query
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleFilterChange = (event, newValue) => {
    setFilter(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter logic for posts based on tab selection and search term
  const filteredPosts = blogPosts.filter((post) => {
    if (filter === 'recent') {
      return new Date(post.date) >= new Date().setDate(new Date().getDate() - 30); // Posts within the last 30 days
    }
    if (filter === 'liked') {
      return post.likes > 10; // Posts with more than 10 likes
    }
    if (searchTerm) {
      return post.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true; // For 'all', return all posts
  });

  const handleCreateNewPost = () => {
    navigate("/Dashboard/Blog/Create-Post");
  };

  return (
    <>
      <DashboardBox>
        {/* Grid container for responsiveness */}
        <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
          <Grid item xs={12} md={6}>
            <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold">
              Blogs
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
            <TextField
              variant="outlined"
              placeholder="Search posts"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ width: isMobile ? '100%' : '400px' }}
            />
          </Grid>
        </Grid>

        {/* FlexBetween for Tab navigation and Create New Post button */}
        <FlexBetween sx={{ flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '1rem' : '0' }}>
          <Tabs
            value={filter}
            onChange={handleFilterChange}
            orientation={isMobile ? 'horizontal' : 'horizontal'}
            variant={isMobile ? 'scrollable' : 'standard'}
            sx={{ width: isMobile ? '100%' : 'auto' }}
          >
            <Tab label="All" value="all" />
            <Tab label="Recent" value="recent" />
            <Tab label="More Liked" value="liked" />
          </Tabs>

          {/* Create New Post Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewPost}
            sx={{ width: isMobile ? '100%' : 'auto' }}
          >
            Create New Post
          </Button>
        </FlexBetween>

        {/* Blog List */}
        <Box sx={{ maxHeight: '600px', overflowY: 'auto', marginTop: '1rem' }}>
          <BlogPostAuthorList posts={filteredPosts} currentUser={user} />
        </Box>
      </DashboardBox>
    </>
  );
};

export default Blog;
