import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ChatDialog = ({ open, handleClose }) => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    const trimmedMessage = message.trim();

    if (trimmedMessage) {
      switch (trimmedMessage) {
        case '1':
          navigate('/services'); // Redirect to services page
          break;
        case '2':
          navigate('/recommendations'); // Redirect to recommendations page
          break;
        case '3':
          navigate('/book-service'); // Redirect to book a service page
          break;
        case '4':
          navigate('/contact'); // Redirect to contact page
          break;
        default:
          // Handle other inquiries or just log it for now
          console.log('User inquiry:', trimmedMessage);
      }
      setMessage(''); // Clear the message field after sending
    }

    handleClose(); // Close the dialog
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          position: 'fixed',
          bottom: 16,
          right: 16,
          margin: 2,
          width: '300px',
          borderRadius: '16px',
          boxShadow: 3,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: '#4A8B0A', color: 'white', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
        ELP Chat
      </DialogTitle>
      <DialogContent>
        <Box sx={{ maxHeight: '200px', overflowY: 'auto', marginBottom: '1rem' }}>
          <Typography variant="body2" sx={{ marginBottom: 2 , marginTop: 2}}>
            Hello, welcome to ELP chat! How may I help you today?
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            1. See all our services
            <br />
            2. Get a quote
            <br />
            3. Book a service
            <br />
            4. Contact us
            <br />
            5. Other inquiries
          </Typography>
        </Box>
        <TextField
          autoFocus
          margin="dense"
          label="Your Message (1-5)"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={handleInputChange}
          sx={{
            borderRadius: '10px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSendMessage} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatDialog;
