import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';
import FlexBetween from '../Box/FlexBetween'; // Assuming FlexBetween is a custom component
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';

const BlogPostList = ({ posts }) => {
    return (
        <Box > {/* Set a fixed height and enable scroll */}
            <Grid container spacing={3}>
                {posts.map((post) => (
                    <Grid item xs={12} sm={12} md={12} key={post.id}>
                        <Card sx={{ display: 'flex', alignItems: 'center' }}>
                            <CardMedia
                                component="img"
                                sx={{
                                    height: 100,
                                    width: 100,
                                    margin: '1rem',
                                }}
                                image={post.image}
                                alt={post.title}
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="body1" color="text.primary" component="div">
                                    {post.title}
                                </Typography>
                                <FlexBetween>
                                    <Typography variant="body1" color="text.secondary">
                                        By {post.author} on {new Date(post.date).toLocaleDateString()}
                                    </Typography>
                                    <FlexBetween>
                                        <CommentIcon />
                                        <Typography variant="body1" color="text.secondary" sx={{ marginLeft: 2 }}>
                                            {post.comments} Comments
                                        </Typography>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <FavoriteIcon />
                                        <Typography variant="body1" color="text.secondary" sx={{ marginLeft: 2 }}>
                                            {post.likes} Likes
                                        </Typography>
                                    </FlexBetween>
                                </FlexBetween>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default BlogPostList;
