// src/App.js
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { jobs } from 'data/data';


// Website Components
import WebsiteLayout from 'components/layouts/WebsiteLayout';
import Home from 'scenes/website/Home';
import AboutUs from 'scenes/website/AboutUs';
import Services from 'scenes/website/Services';
import Blogs from 'scenes/website/Blogs';
import Jobs from 'scenes/website/Jobs';
import ContactUs from 'scenes/website/ContactUs';
import QuoteForm from 'components/website/Forms/QuoteForm';
import BookingForm from 'components/website/Forms/BookingForm';
import ToolsPage from 'scenes/website/BusinessStructure/Tools';
import BusinessStructureRecommender from 'scenes/website/BusinessStructure/BusinessStructureRecomender';
import RecommendationPage from 'scenes/website/BusinessStructure/Recommendation';
import Optimizer from 'scenes/website/BusinessStructure/Optimizer';
import Privacy  from 'components/website/Privacy'
// Dashboard Components
//import DashboardLayout from 'components/layouts/DashboardLayout';
//import DashboardOverview from 'scenes/dashboard/Overview';
//import DashboardUsers from 'scenes/dashboard/Users';
//import DashboardAnalytics from 'scenes/dashboard/Analytics';

// Global Error Pages
import Error404 from 'components/global/404';
import Error504 from 'components/global/504';

// Other Components

import LoadingScreen from 'components/website/Tools/LoadingScreen';
import Login from 'components/global/Login';
import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardOverview from 'scenes/dashboard/DashboardOverview';
import Blog from 'scenes/dashboard/Blog';
import Transactions from 'scenes/dashboard/Transactions';
import Users from 'scenes/dashboard/Users';
import Settings from 'scenes/dashboard/Settings';
import CreatePost from 'components/dashboard/Blog/CreatePost';
import JobAplication from 'scenes/dashboard/Jobs';
import JobDetails from 'components/dashboard/Jobs/JobDetails';
import ApplicantDetails from 'components/dashboard/Jobs/ApplicantDetails';
import JobPost from 'components/dashboard/Jobs/JobPost';
import JobWebDetails from 'components/website/Job/JobWebDetails';
import BlogPost from 'components/website/blog/BlogPost';

function App() {
  const [loading, setLoading] = useState(false);
  

  const handleNavigation = (path) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.location.href = path;
    }, 5000); // Adjust the timeout duration as needed
  };


  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Routes>
          {/* Website Routes */}
          <Route element={<WebsiteLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/About-Us" element={<AboutUs />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Services/Get-A-Quote" element={<QuoteForm />} />
            <Route path="/Services/Book-Consultation" element={<BookingForm />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogPost />} /> 
            <Route path="/Jobs" element={<Jobs />} />
            <Route path="/job/:id" element={<JobWebDetails jobs={jobs}/>} />
            <Route path="/Contact-Us" element={<ContactUs />} />

            {/* Tools Routes */}
            <Route path="/Tools" element={<ToolsPage onNavigate={handleNavigation} />} />
            
            <Route path="/Tools/business-structure-recommender/recommendation" element={<RecommendationPage />} />
            <Route path="/Tools/AI-E-Optimizer" element={<Optimizer />} />

            {/* Catch-all for website: Redirect to 404 */}
            <Route path="*" element={<Error404 />} />
          </Route>

          {/* Dashboard Routes */}
          <Route element={<DashboardLayout />}>
            <Route path="/Dashboard" element={<DashboardOverview />} />
            <Route path="/Dashboard/Blog" element={<Blog />} />
            <Route path="/Dashboard/Blog/Create-Post" element={<CreatePost />} />
            <Route path="/Dashboard/Transactions" element={<Transactions />} />
            <Route path="/Dashboard/Job-Applications" element ={<JobAplication/>}/>
            <Route path="/Dashboard/Job-Applications/new" element={<JobPost />} />
            <Route path="/Dashboard/Job-Applications/:id" element ={<JobDetails/>}/>
            <Route path="/Dashboard/Job-Applications/:jobId/applicants/:applicantId" element={<ApplicantDetails />} />
            <Route path="/Dashboard/Users" element={<Users />} />
            <Route path="/Dashboard/Profile" element={<Settings />} />
                        
          </Route> 

          {/* Global Error Routes (if you want them outside layouts) */}
          <Route path="/ELP-staff-login" element={<Login />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="/504" element={<Error504 />} />
          <Route path="/Privacy-Policy" element={<Privacy />} />
          <Route path="/Tools/business-structure-recommender" element={<BusinessStructureRecommender />} />
        </Routes>
      )}

      {/* Speed Dial for "Let's Chat" - Moved to WebsiteLayout */}
      {/* If you want it globally, place it here, else it's already in WebsiteLayout */}

      {/* Chat Dialog - Moved to WebsiteLayout */}
    </>
  );
}

export default App;
