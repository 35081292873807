// src/components/TransactionDetails.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const TransactionDetails = ({ transaction }) => {
  return (
    <Box sx={{ padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
      <Typography variant="h6">Transaction Details</Typography>
      <Typography><strong>Name:</strong> {transaction.name}</Typography>
      <Typography><strong>Date:</strong> {transaction.date}</Typography>
      <Typography><strong>Service:</strong> {transaction.service}</Typography>
      <Typography><strong>Amount:</strong> KES {transaction.amount}</Typography>
      <Typography><strong>Phone:</strong> {transaction.phone}</Typography>
    </Box>
  );
};

export default TransactionDetails;
