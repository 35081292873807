import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { bookings } from 'data/data'; // Import the booking data
import TransactionDetails from './TransactionDetails'; // Import the TransactionDetails component
import Papa from 'papaparse'; // Import PapaParse for CSV export
import jsPDF from 'jspdf'; // Import jsPDF for PDF export
import html2canvas from 'html2canvas'; // Import html2canvas for capturing content
import FlexBetween from '../Box/FlexBetween';

const TransactionData = () => {
  // Define the columns for the DataGrid
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'service', headerName: 'Service Booked', flex: 1 },
    { field: 'amount', headerName: 'Amount (KES)', flex: 1, type: 'number' },
    { field: 'phone', headerName: 'Phone/Transaction Data', flex: 1 },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.5,
      renderCell: (params) => (
        <Button variant="outlined" onClick={() => handleOpenDialog(params.row)}>
          Show
        </Button>
      ),
    },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [searchText, setSearchText] = useState('');

  const handleOpenDialog = (transaction) => {
    setSelectedTransaction(transaction);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransaction(null);
  };

  // Filter the bookings based on search text
  const filteredRows = bookings.filter((booking) =>
    Object.values(booking).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  // Function to get data for CSV export
  const getData = () => {
    return bookings; // Return the data to be exported
  };

  // Function to handle CSV export
  const handleExportCSV = () => {
    const data = getData(); // Get the data
    const csv = Papa.unparse(data); // Convert to CSV
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transactions.csv'); // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to handle PDF export
  const handleExportPDF = () => {
    const input = document.getElementById('dataGridContainer'); // Get the DataGrid container
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'pt', 'a4'); // Create a new jsPDF instance
      const imgWidth = 500; // Width of the image
      const pageHeight = pdf.internal.pageSize.height; // Get the page height
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height according to width
      let heightLeft = imgHeight;

      let position = 0;
      pdf.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight); // Add the image to PDF
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight); // Add the image to the new page
        heightLeft -= pageHeight;
      }

      pdf.save('transactions.pdf'); // Save the PDF
    });
  };

  return (
    <Box sx={{ width: '100%', padding: '20px' }} id="dataGridContainer">
      {/* Search Field */}
      <FlexBetween>
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        sx={{ marginBottom: '20px', width: '50%' }}
      />
        <Box sx={{ marginTop: '20px' }}>
            <Button variant="outlined" onClick={handleExportCSV} sx={{ marginRight: '10px' }}>
            Export to CSV
            </Button>
            <Button variant="outlined" onClick={handleExportPDF}>
            Export to PDF
            </Button>
        </Box>
      </FlexBetween>
      <Box
        sx={{
          maxHeight: '600px', // Set a maximum height for the DataGrid
          overflow: 'auto', // Enable scrolling
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
          autoHeight={false} // Set to false to allow custom height
          sx={{
            border: 'none',
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
          }}
        />
      </Box>

      {/* Export Buttons */}
      

      {/* Dialog for Transaction Details */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Transaction Details</DialogTitle>
        <DialogContent>
          {selectedTransaction && <TransactionDetails transaction={selectedTransaction} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TransactionData;
