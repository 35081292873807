import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import { shades } from 'theme'; // Adjust this import as needed for your theme

const NumberIncrease = ({ targetNumber, label }) => {
    const [count, setCount] = useState(1); // Starting from 1

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount < targetNumber) {
                    return prevCount + 1; // Increment until target number
                } else {
                    clearInterval(interval); // Clear the interval when the target is reached
                    return prevCount;
                }
            });
        }, 100); // Adjust the interval timing as needed (e.g., 100ms)

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [targetNumber]);

    return (
        <Box textAlign="center">
            <motion.div
                initial={{ opacity: 0 }} // Start invisible
                animate={{ opacity: 1 }} // Animate to visible
                transition={{ duration: 1 }} // Animation duration
                style={{ fontSize: '2rem', fontWeight: 'bold', color: shades.indigo[500] , marginRight:'3rem'}} // Styling for the number
            >
                {count}+
            </motion.div>
            <Typography variant="body1" sx={{ color: shades.black[300], marginTop: '0.5rem', marginRight:'3rem' }}>
                {label}
            </Typography>
        </Box>
    );
};

export default NumberIncrease;
