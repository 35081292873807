import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const BookingForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    country: '',
    service: '',
    companyName: '',
    description: '',
  });

  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission here
  };

  return (
    <Container maxWidth="sm" sx={{height:'110vh'}}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="90vh"
        pt={0.5}
        pl={2}
        pr={2}
        pb={2}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"
        mt={12}
      >
        

        {/* Booking Form Below */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={4}
          width="100%"
        >
          <Typography variant="h5" gutterBottom>
            Book Consultation
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please reserve your spot by completing our initial consultation fee.
          </Typography>
          {/* //<Typography variant="h6" gutterBottom>
            Consultation Fee: KSH 2000
          </Typography> */}
          {/* <Typography variant="h6" gutterBottom>
            Payment Methods
          </Typography> */}
          <Typography variant="body1" gutterBottom>
            Mpesa: ####
          </Typography>
          <Typography variant="body1" gutterBottom>
            Paypal: ####
          </Typography>
          <Typography variant="body1" gutterBottom>
            Wise: ####
          </Typography>
          <Typography variant="body1" gutterBottom>
            We have an ongoing offer for free consultations, submit your details and we will get back to you.
          </Typography>

          {/* Second Form */}
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              variant="outlined"
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              variant="outlined"
            />
            <TextField
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              variant="outlined"
            />
            <TextField
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />            
            <TextField
              label="Request Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              required
              variant="outlined"
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default BookingForm;
