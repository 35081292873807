import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { jobs } from 'data/data'; // Import the job data
import DashboardBox from '../Box/DashboardBox';

const ApplicantDetails = () => {
  const { jobId, applicantId } = useParams(); // Get job ID and applicant ID from URL
  const job = jobs.find((job) => job.id === parseInt(jobId));
  const applicant = job?.applicants.find((applicant) => applicant.id === parseInt(applicantId));

  if (!applicant) {
    return <Typography variant="h6">Applicant not found</Typography>;
  }

  return (
    <DashboardBox p={2}>
      <Typography variant="h4" gutterBottom>
        Applicant Details
      </Typography>
      <Typography variant="h6">Name: {applicant.name}</Typography>
      <Typography variant="body1">Email: {applicant.email}</Typography>
      <Typography variant="body1">
        <strong>CV:</strong>
        <Link href={applicant.cv} target="_blank" rel="noopener" sx={{ ml: 1 }}>
          View CV
        </Link>
      </Typography>
      <Typography variant="body1">
        <strong>Cover Letter:</strong>
        <Link href={applicant.coverLetter} target="_blank" rel="noopener" sx={{ ml: 1 }}>
          View Cover Letter
        </Link>
      </Typography>
    </DashboardBox>
  );
};

export default ApplicantDetails;
