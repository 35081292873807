import * as React from 'react';
import { Box, Typography, Button, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { shades } from 'theme';
import people from 'assets/consultant.png';
import './Home.scss'

export default function Hero() {
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    const img = new Image();
    img.src = people;
    img.onload = () => setLoading(false);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',  // Full container width
        height: isMobile ? '70vh' : '100vh',  // Adjust height for mobile and desktop
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        padding: { md: '2rem ', lg: '5rem 10rem 3rem 10rem' },
        alignItems: 'center',
      }}
    >
      {/* Skeleton loading effect */}
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"  // Ensure the skeleton uses full height
          sx={{ backgroundColor: shades.black[100] }}  // Adjust the skeleton background color
        />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${people})`,
            backgroundSize: 'contain',  // Contain image on small screens, cover on larger screens
            backgroundPosition: isMobile ? 'center' : 'right',  // Center on small screens, right on larger
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',  // Ensure the image also takes full height
            position: 'relative',
          }}
        >
          {/* Content Box */}
          <Box
            sx={{
              backgroundColor: isMobile?'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.1)' , // Semi-transparent overlay
              padding: isMobile ? '1.5rem' : '3rem',  // Adjust padding for small screens
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',  // Center text on small screens
              justifyContent: 'center',
              textAlign: isMobile ? 'center' : 'left',  // Adjust text alignment
            }}
          >
            <Typography 
              variant={isMobile ? 'h3' : 'h2'}  // Smaller text on mobile
              sx={{ 
                mb: 2, 
                mt: 10,
                color: shades.darkgreen, 
                fontWeight: 'bold',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
              }}
            >
              Empowering Entrepreneurs
            </Typography>
            <Typography 
              variant={isMobile ? 'h6' : 'h5'} 
              sx={{ 
                color: shades.black[300], 
                mb: 3,                 
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
              }}
            >
              Tailored and Automated Legal Support
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              textTransform='lowercase'
              sx={{ 
                padding: '0.8rem 1.5rem', 
                backgroundColor: shades.darkgreen, 
                fontWeight: 'bold',
                fontSize: '1rem',
                textTransform: 'uppercase',
                borderRadius: '30px',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                '&:hover': {
                  backgroundColor: shades.lighgreen, // Add a hover effect
                },
              }}
            >
              Learn More
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
