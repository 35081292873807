import React from 'react';
import { Box, Button, Container, TextField, Typography, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from "assets/ELP.png"

const Login = () => {
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.preventDefault(); // Prevent default form submission
        navigate("/Dashboard");
    }

    return (
        <Container 
            maxWidth="xs" 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                minHeight: '100vh' 
            }}
        >
            {/* Logo Section */}
            <Avatar 
                alt="Company Logo" 
                src= {logo}
                sx={{ width: 100, height: 100, marginBottom: 3 }}
            />

            <Typography variant="h5" gutterBottom>
                Welcome Back
            </Typography>

            {/* Form Section */}
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleClick}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                />
                <Button 
                    type="submit" 
                    fullWidth 
                    variant="contained" 
                    sx={{ mt: 2 }}
                >
                    Login
                </Button>
            </Box>
        </Container>
    );
}

export default Login;
