import React, { useState, useEffect } from 'react';
import {
  Box,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Divider,
  AppBar,
  ListItemButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from 'assets/ELP.png'; // Ensure this path is correct
import { shades } from '../../../theme.js'; // Import the shades from theme

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};

const Navbar = () => {
  //const logo  = 'https://entrepreneuriallegalpartner.com/assets/ELP.png'
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();

  const pages = [
    { name: "Home", path: "/" },
    //{ name: "About Us", path: "/About-Us" },
    { name: "Services", path: "/Services" },
    // { name: "Blogs", path: "/Blogs" },
    //  { name: "Jobs", path: "/Jobs" },
    { name: "Tools", path: "/Tools" }, 
    { name: "Contact Us", path: "/Contact-Us" },       
  ];

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: scrolled ? shades.white[500] : 'transparent',
          transition: 'background-color 0.3s ease',
          boxShadow:'none'
        }}
      >
        <Toolbar sx={{ margin: { xs: '0.5rem 3rem', lg: '0.5rem 5rem' } }}>
          <img
            src={logo}
            alt="company logo"
            style={{ height: '40px', width: '40px', marginRight: '2rem', borderRadius: '50%' }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 , color:shades.darkgreen}}>
            {isMobile ? 'ELP' : 'Entrepreneurial Legal Partner'}
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
                sx={{color: shades.lighgreen}}
              >
                <MenuIcon sx={{fontSize:'2rem'}}/>
              </IconButton>
              <motion.div
                initial="closed"
                animate={drawerOpen ? "open" : "closed"}
                variants={variants}
              >
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={toggleDrawer}
                >
                  <Box
                    sx={{ width: 250, padding: '1rem' }}
                    role="presentation"
                    onClick={toggleDrawer}
                    onKeyDown={toggleDrawer}
                  >
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}
                    >
                      <img
                        src={logo}
                        alt="company logo"
                        style={{ height: '40px', width: '40px', marginRight: '1rem', borderRadius: '50%' }}
                      />
                      <Typography variant="h6">
                        ELP
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: shades.darkgreen, height: '2px' }} />
                    <List>
                      {pages.map((page) => (
                        <ListItem
                          button
                          key={page.name}
                          component={Link}
                          to={page.path}
                          sx={{
                            backgroundColor: location.pathname === page.path ? shades.lighgreen : 'transparent',
                            color: location.pathname === page.path ? shades.black[500] : shades.darkgreen,
                            '&:hover': {
                              backgroundColor: shades.darkgreen,
                              color: 'white',
                            },
                          }}
                        >
                          <ListItemText primary={page.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Drawer>
              </motion.div>
            </>
          ) : (
            <Box sx={{ display: 'flex' }}>
              {pages.map((page) => (
                <ListItemButton
                  key={page.name}
                  component={Link}
                  to={page.path}
                  sx={{
                    color: location.pathname === page.path ? 'black' : shades.darkgreen,
                    '&:hover': {
                      backgroundColor: shades.darkgreen,
                      color: 'white',
                    },
                  }}
                >
                  <ListItemText primary={page.name} />
                </ListItemButton>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {!isMobile && <Divider sx={{ bgcolor: shades.darkgreen, height: '2px' }} />}
    </Box>
  );
};

export default Navbar;
