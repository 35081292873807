import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { shades } from 'theme';


const Vision = () => {
  const vision = 'https://entrepreneuriallegalpartner.com/assets/vision.jpg';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // Constants for the header and text content
  const header = 'Our Vision';
  const visionText = 'At Entrepreneurial Legal Partner, we\'re driven by a simple yet powerful vision:';
  const visionBoldText = 'to make entrepreneurship smoother and more secure.';
  const vissionText2 = 'From day one, we\'ve witnessed the unique challenges faced by startups, artists, investors, and tech innovators. ';
  const header2 = 'Our mission'
  const missionText = 'To offer practical support and protection while upholding the highest ethical standards.';

  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        color: 'black',
        padding: isMobile ? '2rem' : '2rem 10rem 2rem 10rem',
        textAlign: 'center',
        minHeight: '50vh',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: isMobile ? '0' : '0 2rem',
        }}
      >
        <img
          src={vision}
          alt="Vision"
          style={{
            maxWidth: '100%',
            height: isMobile? 'auto':'40rem',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'center' : 'flex-start',
          alignItems: isMobile ? 'center' : 'flex-start',
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h3'}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: isMobile ? '1rem' : '2rem',
            borderRadius: '8px',
            marginBottom: '1rem',
            fontWeight: 'bold',
          }}
        >
          {header}
        </Typography>
        <Typography
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: isMobile ? '1rem' : '2rem',
            borderRadius: '8px',
            fontSize: isMobile ? 20: 24,
             textAlign: isMobile ? 'center' : 'left'
          }}
        >
          {visionText} <strong>{visionBoldText}</strong> {vissionText2}
        </Typography>
        <Typography
          variant={isMobile ? 'h5' : 'h3'}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: isMobile ? '1rem' : '2rem',
            borderRadius: '8px',
            marginBottom: '1rem',
            fontWeight: 'bold',
          }}
        >
          {header2}
        </Typography>
        <Typography
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: isMobile ? '1rem' : '2rem',
            borderRadius: '8px',
            fontSize: isMobile ? 20 : 24,
            textAlign: isMobile ? 'center' : 'left',
            marginTop: '1rem',
          }}
        >
          {missionText}
        </Typography>
      </Box>
    </Box>
  );
}

export default Vision;
