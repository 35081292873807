import React, { useState } from 'react';
import { Box, Button, Typography, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleAgree = () => {
    alert('You have agreed to the privacy policy.');
    navigate('/');
  };

  const handleDisagree = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  

  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Effective Date: 11 September 2024
      </Typography>

      <Typography variant="h6">1. Introduction</Typography>
      <Typography paragraph>
        Welcome to Entrepreneurial Legal Partner (ELP). We are committed to protecting your personal information and ensuring your privacy. 
        This Privacy Policy outlines how we collect, use, disclose, and protect your information when you visit our website [www.entrepreneuriallegalpartner.com] 
        or use our services.
      </Typography>

      <Typography variant="h6">2. Information We Collect</Typography>
      <Typography paragraph>
        2.1 Personal Information: When you use our website or services, we may collect personal information such as your name, email address, 
        phone number, and any other details you provide to us.
      </Typography>
      <Typography paragraph>
        2.2 Usage Data: We may collect information about how you interact with our website, including your IP address, browser type, pages visited, 
        and the time and date of your visits.
      </Typography>
      <Typography paragraph>
        2.3 Cookies: We use cookies and similar technologies to enhance your experience on our website. Cookies are small data files stored on 
        your device that help us understand your preferences and improve our services.
      </Typography>

      <Typography variant="h6">3. How We Use Your Information</Typography>
      <Typography paragraph>
        We use the information we collect for the following purposes:
      </Typography>
      <ul>
        <li>To provide and improve our services</li>
        <li>To communicate with you, including sending updates, newsletters, and promotional materials</li>
        <li>To respond to your inquiries and support requests</li>
        <li>To analyze website usage and trends</li>
        <li>To ensure the security and integrity of our website and services</li>
      </ul>

      <Typography variant="h6">4. Sharing Your Information</Typography>
      <Typography paragraph>
        We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
      </Typography>
      <ul>
        <li>Service Providers: We may share your information with third-party service providers who assist us in operating our website and delivering our services.</li>
        <li>Legal Requirements: We may disclose your information if required by law or to protect our rights, privacy, safety, or property, or that of others.</li>
        <li>Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</li>
      </ul>

      <Typography variant="h6">5. Data Security</Typography>
      <Typography paragraph>
        We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. 
        However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </Typography>

      <Typography variant="h6">6. Your Rights</Typography>
      <Typography paragraph>
        You have the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any concerns 
        about how we handle your data, please contact us at [entr.legal.partner@gmail.com].
      </Typography>

      <Typography variant="h6">7. Third-Party Links</Typography>
      <Typography paragraph>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. 
        We encourage you to review their privacy policies before providing any personal information.
      </Typography>

      <Typography variant="h6">8. Changes to This Privacy Policy</Typography>
      <Typography paragraph>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage 
        you to review this policy periodically for any updates.
      </Typography>

      <Typography variant="h6">9. Contact Us</Typography>
      <Typography paragraph>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </Typography>
      <Typography paragraph>
        Entrepreneurial Legal Partner<br/>
        Email: <a href="mailto:entr.legal.partner@gmail.com">entr.legal.partner@gmail.com</a><br/>
        Website: <a href="https://www.entrepreneuriallegalpartner.com" target="_blank" rel="noopener noreferrer">www.entrepreneuriallegalpartner.com</a>
        </Typography>

      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
        label="I have read and agree to the Privacy Policy"
      />

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" disabled={!checked} onClick={handleAgree}>
          Agree
        </Button>
        <Button variant="outlined" color="error" sx={{ ml: 2 }} onClick={handleDisagree}>
          Disagree
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Disagreement Notice</DialogTitle>
        <DialogContent>
          <Typography>
            You have disagreed with our privacy policy. Unfortunately, you will not be able to use our services unless you agree to the terms outlined in the policy.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PrivacyPolicy;
