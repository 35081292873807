import React from 'react';
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick'; // Import the slider
import { shades } from 'theme';
import IP from 'assets/ip.png';
import registration from 'assets/registration.png';
import consultation from 'assets/consultation.png';
import legalDocuments from 'assets/drafting.png';
import regulation from 'assets/regulatio.png';
import international from 'assets/international.jpg';
//import cybersecurity from 'assets/cybersecurity.jpg'; // Add the missing image

const Main = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const services = [
    {
      image: IP,
      service: 'IP Management',
      description: 'We help businesses protect their intellectual property by offering comprehensive IP management services, including trademark registration, patent filing, and legal advice on IP rights.',
    },
    {
      image: registration,
      service: 'Business Registration',
      description: 'Our team assists in the seamless registration of businesses, ensuring all legal requirements are met, and helping you navigate the complexities of business law.',
    },
    {
      image: consultation,
      service: 'Consultation',
      description: 'We provide expert legal consultation tailored to the needs of startups and established businesses, helping you navigate legal challenges with confidence.',
    },
    {
      image: legalDocuments,
      service: 'Legal Documents Drafting',
      description: 'Our legal experts assist in drafting essential legal documents, ensuring all your agreements are compliant and legally binding.',
    },
    {
      image: regulation,
      service: 'Regulatory Compliance',
      description: 'We help businesses adhere to all relevant regulations, ensuring your operations are compliant with local and international laws.',
    },
    {
      image: international,
      service: 'International Legal Services',
      description: 'We offer legal services to businesses operating internationally, navigating the complexities of global law to protect your interests.',
    },
    // {
    //   image: cybersecurity,
    //   service: 'Cybersecurity Analysis Services',
    //   description: 'Our cybersecurity analysis services are designed to safeguard your business against cyber threats. We perform in-depth risk assessments, identify vulnerabilities, and provide solutions to ensure your systems and data remain secure in an increasingly digital and connected world.'
    // }
  ];

  const text = "At Entrepreneurial Legal Partner (ELP), we offer tailored legal solutions for growing businesses. From business registration to IP protection and compliance, our expert team ensures your success every step of the way.";

  const settings = {
    dots: true,           // Enable navigation dots
    infinite: true,       // Loop slides infinitely
    speed: 500,           // Transition speed
    slidesToShow: isMobile? 1: 3,      // Show 3 slides at a time
    slidesToScroll: 1,    // Scroll one slide at a time
    nextArrow: <SampleNextArrow />,   // Custom next arrow
    prevArrow: <SamplePrevArrow />    // Custom previous arrow
  };

  return (
    <Box
      sx={{
        backgroundColor: shades.white[300],
        padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
        textAlign: 'center',
      }}
    >
      <Box sx={{ padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' } }}>
        <Typography variant="h6" color={shades.lighgreen} sx={{ fontWeight: 'bold' }}>
          SERVICES
        </Typography>
        <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 4, fontWeight: 'bold' }}>
          Your happiness is what matters
        </Typography>
        <Typography color={shades.black[300]} sx={{ marginBottom: 6 }}>
          {text}
        </Typography>
      </Box>

      <Slider {...settings}>
        {services.map((service, index) => (
          <Box key={index} sx={{ padding: '1rem' }}>
            <Box
              sx={{
                border: `1px solid ${shades.darkgreen}`,
                padding: '1rem',
                margin: '0 auto',
                width:'100%',
                height:'550px',
                boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                borderRadius: '8px',
                transition: 'transform 0.3s, boxShadow 0.3s',
                position:'relative',
                overflow:'hidden',
                '&:hover': {
                  boxShadow: `0 8px 16px rgba(0, 0, 0, 0.3)`,
                  transform: 'scale(1.02)',
                },
              }}
            >
              <img
                src={service.image}
                alt={service.service}
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  marginBottom: '1rem',
                  
                }}
              />
              <Typography variant="h6" color={shades.black} gutterBottom>
                {service.service}
              </Typography>
              <Divider sx={{ backgroundColor: shades.darkgreen, height: '1px', marginY: '1rem' }} />
              <Typography variant="body2" color={shades.black}>
                {service.description}
              </Typography>
              <Button
                sx={{ backgroundColor: shades.darkgreen, color: shades.white[500], marginTop: 2 }}
                onClick={() => navigate(service.service === 'Consultation' ? '/Services/Book-Consultation' : '/Services/Get-A-Quote')}
              >
                <Typography textTransform="capitalize">
                  {service.service === 'Consultation' ? 'Book' : 'Get a Quote'}
                </Typography>
              </Button>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

// Custom Next Arrow component
const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: shades.darkgreen,
        color: shades.white[500],
        position: 'absolute',
        top: '50%',
        right: '0',
        borderRadius: '50px',
        transform: 'translateY(-50%)',
        zIndex: 1,
        '&:hover': { backgroundColor: shades.darkgreen }
      }}
    >
      &#10095;
    </Button>
  );
};

// Custom Previous Arrow component
const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: shades.darkgreen,
        color: shades.white[500],
        position: 'absolute',
        top: '50%',
        left: '0',
        transform: 'translateY(-50%)',
        borderRadius: '50px',
        zIndex: 1,
        '&:hover': { backgroundColor: shades.darkgreen }
      }}
    >
      &#10094;
    </Button>
  );
};

export default Main;
