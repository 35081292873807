// BlogPost.jsx
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Avatar,
  Divider,
  IconButton,
} from '@mui/material';
import { blogPosts } from 'data/data'; // Adjust the path to your data
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const BlogPost = () => {
  const { id } = useParams(); // Get the ID from the URL
  const postId = parseInt(id, 10);
  const post = blogPosts.find((p) => p.id === postId);

  const [likes, setLikes] = useState(post.likes || 0);
  const [newComment, setNewComment] = useState('');
  const [commentsList, setCommentsList] = useState([]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      setCommentsList([...commentsList, newComment]);
      setNewComment('');
    }
  };

  const handleLike = () => {
    setLikes(likes + 1);
  };

  if (!post) {
    return (
      <Box sx={{ maxWidth: 600, margin: '4rem auto', padding: 2 }}>
        <Typography variant="h4" align="center">Post not found!</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: '4rem auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom align="center">{post.title}</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginBottom: 2 }}>
        <Avatar alt={post.author} src={post.authorImage} sx={{ marginRight: 1 }} />
        <Typography variant="subtitle1" color="text.secondary">{`By ${post.author} on ${new Date(post.date).toLocaleDateString()}`}</Typography>
      </Box>
      <img src={post.image} alt={post.title} style={{ width: '100%', borderRadius: '8px', marginTop: '16px' }} />
      <Typography variant="body1" sx={{ marginTop: 2 }}>{post.description}</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="primary" onClick={handleLike}>
            <ThumbUpIcon />
          </IconButton>
          <Typography variant="body2" sx={{ marginLeft: 1 }}>{likes} Likes</Typography>
        </Box>
      </Box>

      <Divider sx={{ margin: '16px 0' }} />

      <Paper sx={{ marginTop: 3, padding: 2 }}>
        <Typography variant="h6">Comments ({commentsList.length})</Typography>
        <form onSubmit={handleCommentSubmit} style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
          <TextField
            variant="outlined"
            size="small"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            sx={{ flexGrow: 1, marginRight: 1 }}
          />
          <Button variant="contained" type="submit">Submit</Button>
        </form>
        <List sx={{ marginTop: 2 }}>
          {commentsList.map((comment, index) => (
            <ListItem key={index}>
              <ListItemText primary={comment} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default BlogPost;
