
import React from 'react'
import Hero from './Hero'
import Vision from './Vision'
import Services from './Services'
import OurApproach from './OurApproach'

const AboutUs = () => {
  return (
    <>
      <Hero/>
      <Vision/>
      {/* <Services/> */}
      <OurApproach/>
    </>
  )
}

export default AboutUs