import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Link, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const JobApplicantsList = ({ applicants, jobId }) => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  if (applicants.length === 0) {
    return <Typography variant="body2">No applicants yet.</Typography>;
  }

  // Handle navigation to applicant details
  const handleApplicantClick = (applicantId) => {
    navigate(`/Dashboard/Job-Applications/${jobId}/applicants/${applicantId}`);
  };

  return (
    <List>
      {applicants.map((applicant) => (
        <ListItem key={applicant.id} sx={{ borderBottom: '1px solid #ddd', display: 'flex', flexWrap: 'wrap' }}>
          <Grid container spacing={2} alignItems="center">
            {/* Applicant Name and Email */}
            <Grid item xs={12} sm={6}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => handleApplicantClick(applicant.id)} // Navigate on name click
                  >
                    {applicant.name}
                  </Typography>
                }
                secondary={applicant.email}
              />
            </Grid>

            {/* Links to CV and Cover Letter */}
            <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
                <Link href={applicant.cv} target="_blank" rel="noopener">
                  View CV
                </Link>
                <Link href={applicant.coverLetter} target="_blank" rel="noopener">
                  View Cover Letter
                </Link>
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default JobApplicantsList;
