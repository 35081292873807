import { Button, Typography, Box } from '@mui/material';
import DashboardBox from 'components/dashboard/Box/DashboardBox';
import FlexBetween from 'components/dashboard/Box/FlexBetween';
import JobList from 'components/dashboard/Jobs/JobList';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const JobAplication = () => {
  const navigate = useNavigate();

  // Correct the function declaration syntax
  const handleNavigate = () => {
    navigate('/Dashboard/Job-Applications/new'); // Fixed string to use quotes
  };

  return (
    <DashboardBox>
      <FlexBetween sx={{ mb: 3, p: 2, bgcolor: '#f5f5f5', borderRadius: '8px' }}>
        <Typography variant="h4" gutterBottom>
          Job Listings
        </Typography>
        {/* Add the onClick handler here */}
        <Button
          variant="contained"
          color="primary"
          sx={{ boxShadow: 2 }}
          onClick={handleNavigate} // Attach the handler to the button
        >
          Create New Job Post
        </Button>
      </FlexBetween>

      <Box sx={{ bgcolor: '#fff', borderRadius: '8px', boxShadow: 1, p: 2 }}>
        <JobList />
      </Box>
    </DashboardBox>
  );
};

export default JobAplication;
