import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Slider from 'react-slick';
import { shades } from 'theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Partners = () => {
  const partners = [
    {
      companyName: 'InnovateTech',
      executiveName: 'Alice Brown, CEO',
      testimonial: 'Partnering with ELP has been a transformative experience for us. Their expert guidance in legal compliance has empowered our tech ventures significantly.',
    },
    {
      companyName: 'GlobalMed',
      executiveName: 'Robert White, COO',
      testimonial: 'ELP’s meticulous legal support has been pivotal in our global expansion. Their attention to detail and thorough advice ensured our entry into new markets was seamless.',
    },
    {
      companyName: 'EcoTrade',
      executiveName: 'Jessica Green, Founder',
      testimonial: 'Thanks to ELP, we’ve effectively managed our intellectual property rights, safeguarding our innovations and facilitating smoother product launches.',
    },
    {
      companyName: 'FinSecure',
      executiveName: 'David Smith, CFO',
      testimonial: 'ELP’s strategic legal insights helped us navigate complex financial regulations, allowing us to focus on growing our business with confidence.',
    },
    {
      companyName: 'EduWorld',
      executiveName: 'Emily Johnson, Director',
      testimonial: 'ELP has been instrumental in structuring our international partnerships. Their expertise in legal frameworks has helped us create robust and compliant agreements.',
    },
    {
      companyName: 'HealthPlus',
      executiveName: 'Michael Davis, Chief Legal Officer',
      testimonial: 'Our collaboration with ELP has ensured our legal frameworks are solid and scalable, supporting our rapid growth and innovative healthcare solutions.',
    },
  ];

  const longtext = "Explore the experiences of our valued partners who have benefited from ELP's tailored legal services. Their insights highlight the positive impact we've made on their businesses and collaborations.";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        padding: { md: '2rem ', lg: '5rem 10rem 3rem 10rem' },
        textAlign: 'center',
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        alignItems: 'center',
      }}
    >
      {/* Left */}
      <Box flex={1.5} alignContent='center' textAlign={{ xs: 'center', md: 'center', lg: 'left' }} mb={{ md: 3 }} mr={{ lg: 2 }}>
        <Typography variant='h4' color={shades.lighgreen} sx={{ mb: 2 }}>
          Partner Insights
        </Typography>
        <Typography color={shades.black[500]} sx={{ mb: 2 }}>
          {longtext}
        </Typography>
      </Box>

      {/* Divider */}
      <Divider 
        sx={{ 
          borderColor: shades.black[500], 
          borderWidth: { xs: '1px', lg: '1px' }, 
          borderStyle: 'solid',
          height: { xs: '1px', lg: 'auto' }, // Horizontal on small screens, vertical on large
          width: { xs: '100%', lg: '1px' }, // Full width on small screens, thin vertical line on large
          alignSelf: 'stretch',
          maxHeight: { lg: 'calc(100vh - 4rem)' }, // Adjust max height to be shorter than the Box
          mb: { xs: 2, lg: 0 },
        }} 
      />

      {/* Right */}
      <Box flex={1.5} sx={{ maxWidth: '100%', overflow: 'hidden' }}>
        <Slider {...settings}>
          {partners.map((partner, index) => (
            <Box key={index} sx={{ padding: '1rem' }}>
              <Box
                sx={{                
                  borderRadius: '10px',
                  padding: '1.5rem',
                  minHeight: '200px',
                  maxWidth: '50rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: shades.lighgreen }}>
                  {partner.companyName}
                </Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic', mb: 2, color: shades.black[500] }}>
                  "{partner.testimonial}"
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: shades.indigo[400] }}>
                  - {partner.executiveName}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Partners;
