import React from 'react';
import { Box, Grid, Typography, TextField, Button, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { shades } from 'theme';
import contact from "assets/contact3.mp4";


const Main = ({position, marginTop}) => {
  
  return (
    <>    
    <Box      
      sx={{
        backgroundColor: shades.white[500],
        padding: { xs: '1.5rem', sm: '2rem', md: '4rem 8rem', lg: '4rem 15rem' },
        textAlign: 'center',        
      }}
    >
      
      <Typography variant="h4" fontWeight="bold" gutterBottom mt='5rem'>
        Contact Us
      </Typography>
      <Typography variant="body1" color="textSecondary" mb={4}>
        We would love to hear from you. Fill out the form below to get in touch!
      </Typography>

      <Grid container spacing={4} alignItems="center">
        {/* Video Section */}
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Box
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',              
              height: '100%',
            }}
          >
            <video
              src={contact}
              alt="Contact us video"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
              autoPlay
              muted
              loop
            />
          </Box>
        </Grid>

        {/* Form Section */}
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Box sx={{ padding: '1rem' }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography textAlign='start'>First Name</Typography>
                  <TextField
                    fullWidth
                    //abel="First Name"
                    variant="standard" // Use variant="standard" for only bottom border
                    required
                    InputLabelProps={{ style: { fontWeight: 500 } }}
                    sx={{
                      '& .MuiInput-underline:before': { borderBottomColor: shades.darkgreen },
                      '& .MuiInput-underline:after': { borderBottomColor: shades.darkgreen },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography textAlign='start'>Last Name</Typography>
                  <TextField
                    fullWidth
                    //label="Last Name"
                    variant="standard"
                    required
                    InputLabelProps={{ style: { fontWeight: 500 } }}
                    sx={{
                      '& .MuiInput-underline:before': { borderBottomColor: shades.darkgreen },
                      '& .MuiInput-underline:after': { borderBottomColor: shades.darkgreen },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                  <Typography textAlign='start'>Email Address</Typography>
                  <TextField
                    fullWidth
                    //label="Email Address"
                    margin="normal"
                    variant="standard"
                    type="email"
                    required
                    InputLabelProps={{ style: { fontWeight: 500 } }}
                    sx={{
                      '& .MuiInput-underline:before': { borderBottomColor: shades.darkgreen },
                      '& .MuiInput-underline:after': { borderBottomColor: shades.darkgreen },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography textAlign='start'>Company Name</Typography>
                <TextField
                  fullWidth
                  //label="Company"
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiInput-underline:before': { borderBottomColor: shades.darkgreen },
                    '& .MuiInput-underline:after': { borderBottomColor: shades.darkgreen },
                  }}
                />
                </Grid>
              </Grid>             

              <TextField
                fullWidth
                label="Message"
                margin="normal"
                variant="standard"
                multiline
                rows={4}
                required
                InputLabelProps={{ style: { fontWeight: 500 } }}
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: shades.darkgreen },
                  '& .MuiInput-underline:after': { borderBottomColor: shades.darkgreen },
                }}
              />

              <Button
                variant="contained"
                sx={{
                  backgroundColor: shades.darkgreen,
                  color: shades.white[500],
                  marginTop: '1.5rem',
                  textTransform: 'none',
                  borderRadius:'25px',
                  padding: '0.8rem',
                  '&:hover': {
                    backgroundColor: shades.darkgreen[700],
                  },
                }}
                fullWidth
                type="submit"
              >
                Send Message
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default Main;
