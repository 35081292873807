import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const JobPost = () => {
  const [role, setRole] = useState('');
  const [requirements, setRequirements] = useState('');
  const [description, setDescription] = useState('');
  const [salary, setSalary] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
    const jobData = {
      role,
      requirements: requirements.split(',').map(req => req.trim()),
      description,
      salary,
      endDate,
    };
    console.log(jobData);
    // You can also send this data to your backend API
  };

  return (
    <Box p={2}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: '8px' }}>
        <Typography variant="h4" gutterBottom>
          Create New Job Post
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Job Role"
                variant="outlined"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Requirements (comma-separated)"
                variant="outlined"
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Job Description</Typography>
              <ReactQuill
                value={description}
                onChange={setDescription}
                theme="snow"
                style={{ height: '200px' }} // Set height for the editor
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Salary"
                variant="outlined"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
                required
                sx={{marginTop: 6}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="End of Application Date"
                variant="outlined"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Create Job Post
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default JobPost;
