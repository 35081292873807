import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import { shades } from 'theme';

// Reusable FlipCard component
const FlipCard = ({ icon: Icon, title, description }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                perspective: '1000px',
                width: isMobile ? '100%' : '250px',
                height: '250px',
                margin: '2rem',
                '&:hover .flip-card-inner': {
                    transform: 'rotateY(180deg)', // Flip on hover
                },
            }}
        >
            <Box
                className="flip-card-inner"
                sx={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    transition: 'transform 0.8s',
                    transformStyle: 'preserve-3d',
                }}
            >
                {/* Front Side */}
                <Box
                    className="flip-card-front"
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        backfaceVisibility: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'center',
                        boxShadow: 3,
                        borderRadius: '8px',                        
                    }}
                >
                    <Icon sx={{ fontSize: '3rem', color: theme.palette.primary.main , marginLeft: '2rem'}} />
                    <Typography variant="h6" sx={{ mt: '3rem', color: theme.palette.primary.main, marginLeft: '2rem' }}>
                        {title}
                    </Typography>
                </Box>

                {/* Back Side */}
                <Box
                    className="flip-card-back"
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: shades.black[500],
                        backfaceVisibility: 'hidden',
                        transform: 'rotateY(180deg)', // Rotate 180deg for back side
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1rem',
                        textAlign: 'center',
                        boxShadow: 3,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="body2" sx={{ color: 'white' }}>
                        {description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default FlipCard;
