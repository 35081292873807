// src/components/layouts/WebsiteLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/website/Header';
import Footer from 'components/website/Footer';
import ChatDialog from 'components/website/ChatDialog';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ChatIcon from '@mui/icons-material/Chat';
import { shades } from 'theme';

const WebsiteLayout = () => {
  const [openChat, setOpenChat] = React.useState(false);

  const handleOpenChat = () => setOpenChat(true);
  const handleCloseChat = () => setOpenChat(false);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />

      {/* Speed Dial for "Let's Chat" */}
      <SpeedDial
        ariaLabel="Let's Chat"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          color: 'white', 
          '& .MuiSpeedDial-fab': {
            backgroundColor: shades.darkgreen,
            color: 'white',
            '&:hover': {
              backgroundColor: shades.darkgreen[700],
            },
          },
        }}
        icon={<SpeedDialIcon openIcon={<ChatIcon />} />}
        onClick={handleOpenChat} 
      />

      {/* Chat Dialog */}
      <ChatDialog open={openChat} handleClose={handleCloseChat} />
    </>
  );
};

export default WebsiteLayout;
