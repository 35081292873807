import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import NumberIncrease from 'components/website/Home/NumberIncrease';
import Heatmap from 'components/website/Maps/HeatMap'; // Ensure the correct path to the Heatmap component
import React from 'react';
import { shades } from 'theme';

function Countries() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box 
      display="flex"
      p={{ md: '2rem', lg: '3rem 10rem' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      flexGrow={2}      
    >
      <Box flex={1} display="flex" justifyContent="center">
        <Heatmap />
      </Box>
      <Box 
        flex={1} 
        display="flex" 
        flexDirection="column"
        justifyContent='center'
        textAlign= 'left'
        alignItems={{ xs: 'center', md: 'flex-start' }}
        padding={{ xs: '1rem', md: '2rem' }}
        gap={5}
      >
        <Typography color={shades.lighgreen} sx={{ mb: '1rem' }}> STATISTICS </Typography>
        <Typography variant='h6' color={shades.indigo[400]}>
        Our first-class business solutions support our clients’ interests
        </Typography>
        <Box display='flex' flexDirection={isMobile?'column':'row'}>
          <NumberIncrease targetNumber={3} label='Countries we work with'/>
          <NumberIncrease targetNumber={30} label='Law Firms'/>
          <NumberIncrease targetNumber={100} label='Clients'/>
        </Box>
        
      </Box>
    </Box>
  );
}

export default Countries;
