import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlexBetween from '../Box/FlexBetween'; // Assuming FlexBetween is a custom component

const BlogPostAuthorList = ({ posts, currentUser, handleEdit, handleDelete }) => {
    return (
        
        <Grid container spacing={3}>
            {posts.map((post) => (
                <Grid item xs={12} sm={12} md={12} key={post.id}>
                    <Card sx={{ display: 'flex', alignItems: 'center' }}>
                        <CardMedia
                            component="img"
                            sx={{
                                height: 100, // Adjust height to your preference
                                width: 100,  // Adjust width to your preference
                                margin: '1rem',
                            }}
                            image={post.image}
                            alt={post.title}
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="body1" color="text.primary" component="div">
                                {post.title}
                            </Typography>
                            <FlexBetween>
                                <Typography variant="body1" color="text.secondary">
                                    By {post.author} on {new Date(post.date).toLocaleDateString()}                            
                                </Typography>
                                <FlexBetween>
                                    <CommentIcon />
                                    <Typography variant="body1" color="text.secondary" sx={{ marginLeft: 2 }}>
                                        {post.comments} Comments
                                    </Typography>
                                </FlexBetween>
                                <FlexBetween>
                                    <FavoriteIcon />
                                    <Typography variant="body1" color="text.secondary" sx={{ marginLeft: 2 }}>
                                        {post.likes} Likes
                                    </Typography>
                                </FlexBetween>
                                {/* Edit and Delete buttons for the author */}
                                {post.author === currentUser && (
                                    <FlexBetween>
                                        <IconButton onClick={() => handleEdit(post.id)} aria-label="edit">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(post.id)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </FlexBetween>
                                )}
                            </FlexBetween>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default BlogPostAuthorList;
