import { Box } from "@mui/material";
import { styled } from "@mui/system";

const DashboardBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  borderRadius: "1rem",
  boxShadow: "0.15rem 0.2rem 0.15rem 0.1rem rgba(0, 0, 0, .5)",
  padding: theme.spacing(2), // Add padding for better spacing
  // Responsive styles
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3), // Increased padding for small screens and up
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4), // Further increased padding for medium screens and up
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5), // Further increased padding for large screens and up
  },
}));

export default DashboardBox;
