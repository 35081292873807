import React, { useState } from 'react';
import { Box, TextField, useTheme, useMediaQuery, Button } from '@mui/material';
import about from 'assets/aboutUs.jpg';

const Hero = ({ blogs, setFilteredBlogs }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter blogs based on search query
    const filtered = blogs.filter((blog) => 
      blog.title.toLowerCase().includes(query) || 
      blog.author.toLowerCase().includes(query)
    );

    setFilteredBlogs(filtered); // Update the filtered blogs
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${about})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        minHeight: isMobile ? '30vh' : '30vh', // Adjust the minimum height as needed
        position: 'relative',
        marginBottom: '20px',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
          padding: '2rem',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Search bar */}
        <TextField
          variant="outlined"
          placeholder="Search Blogs..."
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            backgroundColor: 'white',
            borderRadius: '5px',
            width: isMobile ? '80%' : '40%', // Adjust width based on screen size
            mt: 8,
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
