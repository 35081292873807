import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import React from 'react';
import { shades } from 'theme';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import FlipCard from 'components/website/Home/FlipCard';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import about from 'assets/about1.mp4';

const AboutUs = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    
    const text1 = 'We aim to bridge the gap between startups and local and international policies.';
    const text2 = 'Our end goal is to provide;';
    const text3 = 'We do this through a tailored approach to jurisdictional tailored services and a legal toolkit that automates processes.';
    const text4 = 'We pride in being a reliable partner to entrepreneurs.';

    const cardData = [
        {
            title: 'Legal Protections',
            description: 'Our end goal is to ensure individual entrepreneurs, startups, and businesses are acquiring the necessary legal protections.',
            icon: GavelRoundedIcon,
        },
        {
            title: 'Regulatory Policies',
            description: 'We help businesses easily integrate regulatory policies to evade penalties.',
            icon: PolicyRoundedIcon,
        },
        {
            title: 'Seamless Legal Process',
            description: 'We provide a seamless legal process during registration, development, and international expansions.',
            icon: CallRoundedIcon,
        },
    ];

    return (
        <Box
            sx={{
                backgroundColor: shades.white[500],
                color: 'black',
                padding: '2rem',
                textAlign: 'center',
                minHeight: '50vh',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        > 
            <Box margin='2rem 0' position='absolute' top={isMobile ? '-8rem' : '-5rem'} zIndex={1} width='80%'>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Box 
                            margin='1.5rem' 
                            display="flex" 
                            flexDirection="column" 
                            alignItems="center" 
                            sx={{ 
                                backgroundColor: 'white', 
                                height: '200px',
                                width: '100%', 
                                boxShadow: 3,
                                borderRadius: '4px'
                            }}
                        >
                            <Typography variant="h6">Call us Now</Typography>
                            <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
                                <IconButton sx={{ fontSize: '2rem' }}>
                                    <CallRoundedIcon fontSize="inherit" />
                                </IconButton>
                                <Box ml={isMobile ? 0 : 2} textAlign={isMobile ? 'center' : 'left'}>
                                    <Typography>We are open: Mon - Fri: 8am - 6pm</Typography>
                                    <Typography variant="h6">123-456-7890</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box 
                            margin='1.5rem' 
                            sx={{ 
                                backgroundColor: shades.lighgreen, 
                                padding: '1rem', 
                                borderRadius: '4px', 
                                textAlign: 'center',
                                height: '200px', 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant='h6' color='white'>Request a free consultation</Typography>
                            <Button variant="contained" color="primary" sx={{ marginTop: '0.5rem', borderRadius: '25px', backgroundColor: shades.darkgreen, '&:hover': {
                                backgroundColor: 'white', color: shades.lighgreen 
                            },}}>Make an appointment</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
          
            <Box position='relative' marginTop={isSmall ? '28rem' : '10rem'} sx={{ maxWidth: '1500px', width: '100%', textAlign: 'left' }}>
                <Typography color={shades.lighgreen} sx={{ mb: '1rem' }}>
                    ABOUT US
                </Typography>
                <Typography variant='h6' color={shades.indigo[400]}>
                    Why you can trust us
                </Typography>
                <Typography sx={{ mb: '1rem', color: shades.black[300] }}>
                    {text1}
                </Typography>
                <Typography sx={{ mb: '1rem', color: shades.black[300] }}>
                    {text2}
                </Typography>
                
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', width:'100%' , height:'100%'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', width: isMobile?'80%': '70%' }}>
                        {cardData.map((card, index) => (
                            <FlipCard
                                key={index}
                                icon={card.icon}
                                title={card.title}
                                description={card.description}
                            />
                        ))}
                    </Box>
                    <Box sx={{ margin: '0 1rem', width: isMobile?'80%':'30%' }}>
                        <video controls autoPlay loop width='100%' style={{borderRadius:'8px'}}>
                            <source src={about} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>
                
                <Typography sx={{ mb: '1rem', color: shades.black[300] }}>
                    {text3}
                </Typography>
                <Typography sx={{ mb: '1rem', color: shades.black[300] }}>
                    {text4}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <IconButton>
                        <ArrowBackRoundedIcon />
                    </IconButton>
                    <IconButton>
                        <ArrowForwardRoundedIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default AboutUs;
