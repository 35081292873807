import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { shades } from 'theme';
import about from 'assets/about.mp4';
import { useNavigate } from 'react-router-dom';

const Section1 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                backgroundColor: '#F5F5F5',
                padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
                textAlign: 'center',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row', // Column on mobile, row on larger screens
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack elements vertically
                    justifyContent: 'center', // Center vertically
                    width: isMobile ? '80%' : '50%', 
                    textAlign: isMobile ? 'center' : 'left', // Center text on mobile
                    
                }}>
                    <Typography variant="h5" fontWeight="bold" color={shades.lighgreen} sx={{mb:2}}>WHY US?</Typography>
                    <Typography variant="h4" fontWeight="bold" sx={{mb:2}}>Our Vision, Your Success</Typography>
                    <Typography color={shades.black[300]} sx={{mb:4}}>
                        At <Typography component="span" fontWeight="bold" color={shades.lighgreen}>Entrepreneurial Legal Partner (ELP)</Typography>, we believe that every business, whether a startup or an established enterprise, deserves a trusted legal partner. We stand out because we blend innovation with experience, ensuring that our legal solutions are not just timely but also forward-thinking.
                    </Typography>
                    <Button 
                        sx={{
                            backgroundColor: shades.lighgreen,
                            color: 'white',
                            borderRadius: '25px',
                            marginTop: '1rem',
                            width:'150px', // Add margin for spacing
                            alignSelf: isMobile ? 'center' : 'flex-start',
                            mb: isMobile? 2 : 0,
                        }}
                        onClick={() => navigate("/Contact-Us")} 
                    >
                        Contact Us
                    </Button>
                </Box>
                <Box sx={{
                    margin: '1rem',
                    width: isMobile ? '80%' : '50%', // Adjust width for mobile
                    display: 'flex', // Flex to center video
                    justifyContent: 'center', // Center the video container
                }}>
                    <video controls autoPlay loop width='100%' style={{ borderRadius: '8px' }}>
                        <source src={about} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            </Box>
        </Box>
    );
};

export default Section1;
