import React from 'react';
import { Typography, Button, Box, useMediaQuery } from '@mui/material';
import DashboardBox from 'components/dashboard/Box/DashboardBox';
import FlexBetween from 'components/dashboard/Box/FlexBetween';
import TransactionData from 'components/dashboard/Transaction/TransactionData'; // Ensure this is correct
import EventNoteIcon from '@mui/icons-material/EventNote';

const Transactions = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <DashboardBox>
        <FlexBetween sx={{ marginBottom: '1rem', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : 'row' }}>
          <Box display="flex" alignItems="center" sx={{ flexGrow: 1, mb: isSmallScreen ? '1rem' : 0 }}>
            <EventNoteIcon sx={{ marginRight: '8px', color: 'primary.main', fontSize: '2rem' }} />
            <Typography variant={isSmallScreen ? 'h6' : 'h5'} gutterBottom fontWeight="bold">
              Service Booking Transactions
            </Typography>
          </Box>
        </FlexBetween>
        <Typography variant="body2" sx={{ marginBottom: '1rem', color: 'text.secondary' }}>
          Below are the details of the bookings made for various services. You can view detailed information by clicking on the "Show" button in each row.
        </Typography>
        <TransactionData />
      </DashboardBox>
    </>
  );
};

export default Transactions;
