import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Avatar, Grid, Paper, IconButton } from '@mui/material';
import { shades } from 'theme';
import EditIcon from '@mui/icons-material/Edit'; // Import EditIcon

const Settings = () => {
    // Dummy user data
    const user = {
        name: 'John Doe',
        email: 'johndoe@example.com',
        role: 'Admin',
        profilePicture: '/assets/ELP.png' // Dummy profile picture
    };

    // Profile data for rendering
    const profileData = [
        { title: 'Name', details: user.name },
        { title: 'Email', details: user.email },
        { title: 'Role', details: user.role }
    ];

    // State for form inputs
    const [profilePic, setProfilePic] = useState(user.profilePicture);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleProfilePicChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePic(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePasswordChange = (event) => {
        event.preventDefault();
        // Logic to handle password change
        alert('Password changed successfully!');
    };

    return (
        <Paper elevation={1} sx={{ padding: 4, maxWidth: 'auto', margin: 'auto', backgroundColor: shades.white[500] }}>
            <Typography variant="h5" gutterBottom textAlign='center'>
                User Profile
            </Typography>

            {/* Profile Picture and User Info */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 4 }}>
                <Avatar 
                    src={profilePic}
                    sx={{ width: 150, height: 150, marginBottom: 2 }}
                />
                {/* Change Profile Picture */}
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Change Profile Picture
                    </Typography>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="profile-pic-upload"
                        type="file"
                        onChange={handleProfilePicChange}
                    />
                    <label htmlFor="profile-pic-upload" justifyContent='center'>
                        <Button variant="contained" component="span" color="primary" startIcon={<EditIcon />}>
                            <Typography textTransform='lowercase'>Upload New Picture</Typography>
                        </Button>
                    </label>
                </Box>               
                {/* User Details */}
                <Box sx={{ width: { xs: '70%', sm: '70%', lg: '40%', xl: '40%' } }}>
                    {profileData.map((item) => (
                        <Box key={item.title} sx={{ marginBottom: 1 }} display='flex' justifyContent='space-between'>
                            <Typography variant="body1" fontWeight="bold">{item.title}:</Typography>
                            <Typography variant="body2" color="textSecondary">{item.details}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Change Password */}
            <Box component="form" onSubmit={handlePasswordChange} sx={{ width: { xs: '70%', sm: '70%', lg: '40%', xl: '40%' }, margin: 'auto' }}>
                <Typography variant="h6" gutterBottom textAlign='center'>
                    Change Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Current Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="New Password"
                            type="password"
                            fullWidth
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button type="submit" variant="contained" color="primary">
                        Change Password
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default Settings;
