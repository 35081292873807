import React, { useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import about from 'assets/aboutUs.jpg';

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isImageLoaded, setIsImageLoaded] = useState(false); // State to track image loading

  return (
    <Box
      sx={{
        backgroundImage: isImageLoaded ? `url(${about})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        minHeight: '30vh', // Adjust the minimum height as needed
        position: 'relative',
        marginTop: '5rem',
      }}
    >
      {/* Skeleton to show while the image is loading */}
      {!isImageLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1, // Ensures the skeleton is above other content
          }}
        />
      )}

      <img
        src={about}
        alt="About us"
        style={{ display: 'none' }} // Hide the actual image element
        onLoad={() => setIsImageLoaded(true)} // Set to true when image is loaded
      />

      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)', // Semi-transparent overlay
          padding: '2rem',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h2' sx={{ mb: 2, mt: 5 }} textAlign={isMobile ? 'center' : 'flex-start'}>
          You are our priority
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
