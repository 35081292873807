import React from 'react';
import { Box, Typography, Button, useMediaQuery, useTheme, LinearProgress } from '@mui/material';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import BoltIcon from '@mui/icons-material/Bolt';
import CloudIcon from '@mui/icons-material/Cloud';
import MemoryIcon from '@mui/icons-material/Memory';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const Optimizer = () => {
  const handleRequestDemo = () => {
    window.open('https://calendly.com/decrakerubo/fels', '_blank');
  };

  const showMessage = () => {
    return alert('Snap! We are on our way to cloud. Check back soon');
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const cogs = [
    {icon: ModelTrainingIcon, statistics: '26+', description: 'Models Tested'},
    {icon: BoltIcon, statistics: '50+', description: 'Energy-Efficient Algorithms'},
    {icon: CloudIcon, statistics: '30+', description: 'Green Cloud Services'},
    {icon: MemoryIcon, statistics: '30+', description: 'Edge Technologies'},
  ];

  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(to right, #000000, #333333)', // Gradient background
        color: 'white',
        minHeight: '100vh',
        width: '100%' ,
        // Ensure the Box spans full width
      }}
    >
      <Box 
        sx={{ 
         padding: isMobile ? '2rem' : '10rem',
          marginTop: '5rem', 
          backgroundColor: 'black',
          width: '100%', // Full width
          maxWidth: 'none' // Removes the max-width constraint
        }}
      >
        <Box 
          display='flex' 
          flexDirection='column' 
          alignItems='center' 
          justifyContent='center' 
          margin='2rem'
        >
          <Typography variant="h1" fontWeight='bold' mt='2rem'>OptimizaE</Typography>
          
          <Box 
            display='flex' 
            flexDirection='row' 
            justifyContent='center' 
            mt='2rem'
          >
            <Typography fontSize='1.5rem' fontWeight='bold' mx='1rem'>Easy</Typography>
            <Typography fontSize='1.5rem' fontWeight='bold' mx='1rem'>Efficient</Typography>
            <Typography fontSize='1.5rem' fontWeight='bold' mx='1rem'>Energy-Saving</Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 20, mx: { xs: 2, sm: 2, md: 5, lg: 5, xl: 10 }, textAlign: 'center' }}>
          <Typography
            variant="h1"
            fontWeight="bold"
            gutterBottom
            sx={{ color: '#004d00' }}
          >
            Accessible and Greener Intelligent Process
          </Typography>
          <Typography
            fontSize="1.5rem"
            fontWeight="bold"
            mx="1rem"
            mt="2rem"
          >
            Query in your AI tasks and features, to generate real-time AI optimization insights and Energy-Saving tools.
          </Typography>
          <Typography
            fontSize="1.5rem"
            fontWeight="bold"
            mx="1rem"
            mt="0.5rem"
          >
            Note: A basic technical understanding is required for 30% of the input phase.
          </Typography>
          
          <Button
            onClick={handleRequestDemo}
            sx={{
              mt: '2rem',
              padding: '1em 2.5em',
              color: '#ffffff',
              backgroundColor: '#004d00', 
              borderRadius: '6px',
              fontWeight: 'bold',
              transition: 'background 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: '#003300', 
                transform: 'scale(1.05)',
              }
            }}
          >
            Request a Demo
          </Button>
        </Box>
      </Box>

      <Box 
        sx={{           
          color: 'white',
          marginTop: '2rem',
          width: '100%', // Full width
          maxWidth: 'none', // Ensure this Box has enough height
          textAlign: 'center',
          padding: isMobile ? '2rem' : '10rem',
          
        }}
        alignItems='center' 
        justifyContent='center' 
      >
        <Typography mt="2rem" fontSize='1.5rem'>
          OptimizaE is an AI-powered solution designed to help organizations and developers adopt Green AI technologies and climate-conscious practices.
        </Typography>
        <Typography mt="2rem" fontSize='1.5rem' whiteSpace='normal'>
          By optimizing your infrastructure with energy-efficient algorithms and green development tools, you can enhance performance while contributing to a low-carbon future.
        </Typography>
        <Typography mt="2rem" fontSize='1.5rem' whiteSpace='normal'>
          Through the integration of Edge AI, more access is fostered, and more energy and water conserved.
        </Typography>
        <Grid2 container spacing={2}>
          {cogs.map(({ icon, statistics, description }, index) => (
            <Grid2 item xs={12} sm={6} md={6} lg={3} xl={3} key={index}>
              <Box 
                display='flex' 
                flexDirection='column' 
                alignItems='center' 
                justifyContent='center' 
                m='2rem'
                sx={{ 
                  backgroundColor: 'black',
                  padding: '2rem',
                  borderRadius: '10px',
                  width: '80%' ,
                  height: '100%',
                }}
              >
                <Box 
                  display='flex' 
                  alignItems='center' 
                  justifyContent='center' 
                  sx={{                    
                    padding: '1rem', 
                    width: '100px', 
                    height: '100px' 
                  }}
                >
                  {React.createElement(icon, { sx: { fontSize: '3rem', color: '#004d00' } })}
                </Box>
                <Typography mt='1rem' fontSize='1.5rem' fontWeight='bold'>{statistics}</Typography>
                <Typography mt='1rem' fontSize='1.5rem' textAlign='center'>{description}</Typography>
              </Box>
            </Grid2>
          ))}
        </Grid2>
        <Button
            onClick={handleRequestDemo}
            sx={{
              mt: '5rem',
              padding: '1em 2.5em',
              color: '#ffffff',
              backgroundColor: '#004d00', 
              borderRadius: '6px',
              fontWeight: 'bold',
              transition: 'background 0.3s ease, transform 0.3s ease',
              '&:hover': {
                backgroundColor: '#003300', 
                transform: 'scale(1.05)',
              }
            }}
          >
            Request a Demo
          </Button>
      </Box>

      <Box
      sx={{
        color: 'white',
        marginTop: '2rem',
        width: '100%',
        textAlign: 'center',
        padding: isMobile ? '2rem' : '4rem', // Adjusted padding for larger screens
        borderRadius: '10px',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between', // Adjusted for proper spacing
        gap: { xs: '1rem', sm: '1rem', md: '2rem', lg: '4rem' }, // Added gap for better spacing
      }}
    >
      <Box
        sx={{
          backgroundColor: '#333333',
          color: 'white',
          flex: { xs: '1', lg: '0.8' }, // Responsive flex property
          padding: '1em',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>Tracking our Users' Inferential Carbon</Typography>
        <LinearProgress
          variant="determinate"
          value={50}
          sx={{
            height: '40px',
            backgroundColor: 'rgba(0,0,0,0.4)',
            borderRadius: '12px',
            width: '100%',
            mt: { xs: '2rem', lg: '0' }, // Responsive margin top
          }}
        />
      </Box>
      <Button
        onClick={showMessage}
        sx={{
          height: '8rem',
          width: { xs: '100%', sm: 'auto' }, // Responsive width
          backgroundColor: '#004d00',
          borderRadius: '12px',
          padding: '1em',
          color: '#ffffff',
          boxShadow: '0 6px 12px rgba(0,0,0,0.3)',
          cursor: 'pointer',
          textAlign: 'center',
          transition: 'background 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            backgroundColor: '#003300',
            boxShadow: '0 8px 16px rgba(0,0,0,0.4)',
          },
        }}
      >
        Join Our Inference Tracker
      </Button>
    </Box>
    </Box>
  );
};

export default Optimizer;
