import React from 'react'
import BloggerDashboard from './BloggerDashboard'

const DashboardOverview = () => {
  return (
    <div>
      <BloggerDashboard />
    </div>
  )
}

export default DashboardOverview