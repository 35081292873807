import React from 'react';
import Hero from './Hero';
import Main from './Main';
import Section from './Section';
import { useMediaQuery, useTheme } from '@mui/material';

const ContactUs = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Hero />
      <Section />
      <Main
        position="relative"
        marginTop={isSmall ? '26rem' : '10rem'}
      />
    </>
  );
};

export default ContactUs;
