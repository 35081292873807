import React, { useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, TextField, Paper, Button, Snackbar } from '@mui/material';
import tools from 'assets/tools.jpg';
import ToolIcon from '@mui/icons-material/Build';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    fullNames: '',
    jobTitle: '',
    portfolioLink: '',
    linkedinLink: '',
    resumeLink: '',
    cvFile: null,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormValues({
      ...formValues,
      cvFile: e.target.files[0], // Store the uploaded file
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., API call) including the uploaded file.
    console.log(formValues);
    setShowForm(false);
    window.scrollTo(0, 0);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${tools})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color: 'white',
          padding: '2rem',
          textAlign: 'center',
          minHeight: isMobile ? '30vh' : '40vh', // Adjust the minimum height as needed
          position: 'relative',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
            padding: '2rem',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <ToolIcon style={{ marginRight: '10px' }} />
            <Typography variant="h6" sx={{marginRight:'10rem'}}>Tools Dashboard</Typography>
          </Box>
          <Button variant="contained" color="secondary" onClick={() => setShowForm(!showForm)}>
            {showForm ? 'Close Form' : 'Join ELPlab'}
          </Button>
        </Box>
      </Box>

      {showForm && (
        <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          width: '100%', 
          padding: '20px', 
          marginTop: '20px' 
        }}
      >
        <Paper 
          elevation={3} 
          sx={{ 
            width: { xs: '100%', md: '100%', lg: '50rem', xl:'60rem' }, 
            padding: '20px' 
          }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Join ELPlab
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Email"
                variant="outlined"
                name="email"
                value={formValues.email}
                onChange={handleFormChange}
                fullWidth
                required
              />
              <TextField
                label="Full Names"
                variant="outlined"
                name="fullNames"
                value={formValues.fullNames}
                onChange={handleFormChange}
                fullWidth
                required
              />
              <TextField
                label="Job Title"
                variant="outlined"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={handleFormChange}
                fullWidth
                required
              />
              <TextField
                label="Portfolio Link"
                variant="outlined"
                name="portfolioLink"
                value={formValues.portfolioLink}
                onChange={handleFormChange}
                fullWidth
              />
              <TextField
                label="LinkedIn Link"
                variant="outlined"
                name="linkedinLink"
                value={formValues.linkedinLink}
                onChange={handleFormChange}
                fullWidth
              />
              <TextField
                label="Resume Link"
                variant="outlined"
                name="resumeLink"
                value={formValues.resumeLink}
                onChange={handleFormChange}
                fullWidth
              />
              <Button variant="contained" component="label" >
                Upload CV
                <input
                  type="file"
                  name="cvFile"
                  onChange={handleFileChange}
                  accept=".pdf, .doc, .docx"
                  hidden
                />
              </Button>
              {formValues.cvFile && (
                <Typography variant="body2" color="textSecondary">
                  Uploaded: {formValues.cvFile.name}
                </Typography>
              )}
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
        </Box>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          Form submitted successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Hero;
