import AdminUsers from 'components/dashboard/Admin/AdminUsers'
import DashboardBox from 'components/dashboard/Box/DashboardBox'
import React from 'react'

const Users = () => {
  return (
    <DashboardBox>
      <AdminUsers/>
    </DashboardBox>
  )
}

export default Users