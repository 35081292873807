import React from 'react'
import Hero from './Hero'
import Main from './Main'
import Section1 from './Section1'
import Success from '../Home/Success'

const Services = () => {
  return (
    <>
      <Hero/>
      <Main/>
      <Section1/>
      <Success/>
    </>
  )
}

export default Services