import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Grid, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = () => {
    // Handle the form submission logic here
    console.log({ title, content, tags, category, image });
  };

  const handleCancel = () => {
    // Reset or navigate away
    setTitle('');
    setContent('');
    setTags('');
    setCategory('');
    setImage(null);
  };

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', padding: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Create a New Blog Post
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        {/* Title Input */}
        <TextField
          label="Post Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {/* Category Select */}
        <FormControl fullWidth>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            label="Category"
          >
            <MenuItem value="technology">Technology</MenuItem>
            <MenuItem value="business">Business</MenuItem>
            <MenuItem value="lifestyle">Lifestyle</MenuItem>
            <MenuItem value="education">Education</MenuItem>
          </Select>
        </FormControl>

        {/* Content Editor */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Content
          </Typography>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={handleContentChange}
            style={{ height: '300px' }}
          />
        </Box>

        {/* Tags Input */}
        <TextField
          label="Tags (comma separated)"
          variant="outlined"
          fullWidth
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />

        {/* Image Upload */}
        <Box>
          <Button variant="outlined" component="label" startIcon={<CloudUploadIcon />}>
            Upload Featured Image
            <input type="file" hidden onChange={handleImageUpload} />
          </Button>
          {image && <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>{image.name}</Typography>}
        </Box>

        {/* Actions */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              fullWidth
            >
              Publish Post
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreatePost;
