export const blogPostStatisticsData = [
    {
      id: "Posts",
      color: "hsl(109, 97%, 88%)", // Light green
      data: [
        { x: "Jan", y: 12 },
        { x: "Feb", y: 8 },
        { x: "Mar", y: 14 },
        { x: "April", y: 10 },
        { x: "May", y: 15 },
        { x: "June", y: 18 },
        { x: "July", y: 9 },
        { x: "Aug", y: 12 },
        { x: "Sept", y: 13 },
        { x: "Oct", y: 16 },
        { x: "Nov", y: 11 },
        { x: "Dec", y: 14 },
      ],
    },
    {
      id: "Comments",
      color: "hsl(182, 87%, 39%);", // Light purple
      data: [
        { x: "Jan", y: 5 },
        { x: "Feb", y: 4 },
        { x: "Mar", y: 7 },
        { x: "April", y: 6 },
        { x: "May", y: 9 },
        { x: "June", y: 10 },
        { x: "July", y: 8 },
        { x: "Aug", y: 7 },
        { x: "Sept", y: 10 },
        { x: "Oct", y: 11 },
        { x: "Nov", y: 9 },
        { x: "Dec", y: 12 },
      ],
    },
    {
      id: "Likes",
      color: "hsl(150, 50%, 60%);", // Pink
      data: [
        { x: "Jan", y: 20 },
        { x: "Feb", y: 18 },
        { x: "Mar", y: 22 },
        { x: "April", y: 24 },
        { x: "May", y: 30 },
        { x: "June", y: 28 },
        { x: "July", y: 25 },
        { x: "Aug", y: 27 },
        { x: "Sept", y: 26 },
        { x: "Oct", y: 29 },
        { x: "Nov", y: 31 },
        { x: "Dec", y: 34 },
      ],
    },
  ];
  
  export const blogPosts = [
    {
      id: 1,
      title: 'Understanding React Hooks',
      author: 'John Doe',
      date: '2024-09-20',
      image: 'https://pixabay.com/photos/woman-elderly-wrinkes-female-952506/',
      comments: 5,
      likes: 20,
      description: "React Hooks allow you to use state and other React features without writing a class. They are an excellent addition to React, simplifying the way we handle stateful logic.",
    },
    {
      id: 2,
      title: 'A Guide to Node.js',
      author: 'Jane Smith',
      date: '2024-09-18',
      image: 'https://pixabay.com/photos/woman-elderly-wrinkes-female-952506/', 
      comments: 7,
      likes: 30,
      description: "Node.js is a powerful JavaScript runtime built on Chrome's V8 engine. It enables developers to build fast, scalable server-side applications using JavaScript.",
    },
    {
      id: 3,
      title: 'CSS Grid vs Flexbox',
      author: 'Alice Johnson',
      date: '2024-09-15',
      image: 'https://pixabay.com/photos/woman-elderly-wrinkes-female-952506/', 
      comments: 30,
      likes: 80,
      description: "CSS Grid and Flexbox are two powerful layout systems in CSS. Understanding when to use each can significantly enhance your web development skills.",
    },
    {
      id: 4,
      title: 'Best Practices in JavaScript',
      author: 'David Miller',
      date: '2024-09-10',
      image: 'https://pixabay.com/photos/laptop-computer-keyboard-work-2838921/',
      comments: 12,
      likes: 50,
      description: "Following best practices in JavaScript can improve code quality and maintainability. This blog explores various techniques to write clean and efficient code.",
    },
    {
      id: 5,
      title: 'Building REST APIs with Express',
      author: 'Sarah Brown',
      date: '2024-09-08',
      image: 'https://pixabay.com/photos/coding-code-programming-web-development-1853305/',
      comments: 25,
      likes: 45,
      description: "Express is a minimal and flexible Node.js web application framework that provides a robust set of features for building web and mobile applications.",
    },
    {
      id: 6,
      title: 'State Management with Redux',
      author: 'Michael Clark',
      date: '2024-09-05',
      image: 'https://pixabay.com/photos/code-technology-computer-html-web-1076536/',
      comments: 10,
      likes: 40,
      description: "Redux is a predictable state container for JavaScript apps. It helps you manage application state in a consistent way across different environments.",
    },
    {
      id: 7,
      title: 'Introduction to TypeScript',
      author: 'Jennifer Taylor',
      date: '2024-09-02',
      image: 'https://pixabay.com/photos/code-html-digital-coding-web-1076534/',
      comments: 15,
      likes: 60,
      description: "TypeScript is a typed superset of JavaScript that compiles to plain JavaScript. It offers static typing and improved tooling for large codebases.",
    },
    {
      id: 8,
      title: 'Building Responsive Websites',
      author: 'Matthew Wilson',
      date: '2024-08-30',
      image: 'https://pixabay.com/photos/technology-computer-code-programming-1853303/',
      comments: 18,
      likes: 55,
      description: "Responsive web design is about creating web pages that look good on all devices. It provides a seamless user experience across different screen sizes.",
    },
    {
      id: 9,
      title: 'Modern Web Development with Next.js',
      author: 'Sophia Martinez',
      date: '2024-08-28',
      image: 'https://pixabay.com/photos/computer-laptop-technology-keyboard-2838199/',
      comments: 20,
      likes: 65,
      description: "Next.js is a React framework that enables server-side rendering and generating static websites for React based applications. It offers many features that enhance performance and user experience.",
    },
    {
      id: 10,
      title: 'Getting Started with MongoDB',
      author: 'Lucas White',
      date: '2024-08-25',
      image: 'https://pixabay.com/photos/code-code-editor-codeing-computer-1839406/',
      comments: 10,
      likes: 35,
      description: "MongoDB is a NoSQL database that stores data in flexible, JSON-like documents. It's designed for scalability and performance, making it a popular choice for modern web applications.",
    },
  ];
  

  // src/data/transactionData.js
export const bookings = [
  { id: 1, name: 'Alice Johnson', date: '2024-09-01', service: 'Consultation', amount: 5000, phone: '+254700123456' },
  { id: 2, name: 'Bob Smith', date: '2024-09-03', service: 'IP Management', amount: 7000, phone: '+254701123457' },
  { id: 3, name: 'Charlie Daniels', date: '2024-09-05', service: 'Business Registration', amount: 10000, phone: '+254702123458' },
  { id: 4, name: 'Diana Lee', date: '2024-09-07', service: 'Consultation', amount: 4500, phone: '+254703123459' },
  { id: 5, name: 'Ethan Hunt', date: '2024-09-09', service: 'Legal Documents Drafting', amount: 6000, phone: '+254704123460' },
  { id: 6, name: 'Fiona Evans', date: '2024-09-11', service: 'Consultation', amount: 5000, phone: '+254705123461' },
  { id: 7, name: 'George Taylor', date: '2024-09-13', service: 'Regulatory Compliance', amount: 8000, phone: '+254706123462' },
  { id: 8, name: 'Hannah Martin', date: '2024-09-15', service: 'International Legal Services', amount: 12000, phone: '+254707123463' },
  { id: 9, name: 'Ian McCarthy', date: '2024-09-17', service: 'Business Registration', amount: 10000, phone: '+254708123464' },
  { id: 10, name: 'Jenna Fox', date: '2024-09-19', service: 'IP Management', amount: 7500, phone: '+254709123465' },
  { id: 11, name: 'Kyle Adams', date: '2024-09-21', service: 'Consultation', amount: 4500, phone: '+254710123466' },
  { id: 12, name: 'Laura Palmer', date: '2024-09-23', service: 'Legal Documents Drafting', amount: 6800, phone: '+254711123467' },
  { id: 13, name: 'Mike Green', date: '2024-09-25', service: 'Regulatory Compliance', amount: 8000, phone: '+254712123468' },
  { id: 14, name: 'Nina Brown', date: '2024-09-27', service: 'International Legal Services', amount: 12500, phone: '+254713123469' },
  { id: 15, name: 'Oliver Stone', date: '2024-09-29', service: 'IP Management', amount: 6800, phone: '+254714123470' },
  { id: 16, name: 'Paula White', date: '2024-09-30', service: 'Consultation', amount: 5500, phone: '+254715123471' },
  { id: 17, name: 'Quentin Blake', date: '2024-10-02', service: 'Business Registration', amount: 9500, phone: '+254716123472' },
  { id: 18, name: 'Rachel King', date: '2024-10-04', service: 'Regulatory Compliance', amount: 7800, phone: '+254717123473' },
  { id: 19, name: 'Sam Wilson', date: '2024-10-06', service: 'Legal Documents Drafting', amount: 6200, phone: '+254718123474' },
  { id: 20, name: 'Tina Brooks', date: '2024-10-08', service: 'International Legal Services', amount: 11500, phone: '+254719123475' }
];

export const jobs = [
  {
    id: 1,
    role: 'Frontend Developer',
    level: 'Mid Level',
    requirements: ['React', 'JavaScript', 'CSS', 'HTML'],
    description: 'Develop UI components for our web platform.',
    salary: 'KES 120,000',
    endDate: '2024-10-15',
    applicants: [
      { id: 1, name: 'John Doe', email: 'john@example.com', cv: '/documents/john_cv.pdf', coverLetter: '/documents/john_cover_letter.pdf' },
      { id: 2, name: 'Jane Smith', email: 'jane@example.com', cv: '/documents/jane_cv.pdf', coverLetter: '/documents/jane_cover_letter.pdf' }
    ]
  },
  {
    id: 2,
    role: 'Backend Developer',
    level: 'Senior',
    requirements: ['Node.js', 'MongoDB', 'Express'],
    description: 'Build and maintain server-side applications.',
    salary: 'KES 150,000',
    endDate: '2024-11-01',
    applicants: []
  },
  {
    id: 3,
    role: 'UI/UX Designer',
    level: 'Junior',
    requirements: ['Figma', 'Adobe XD', 'User Research'],
    description: 'Design user-centric web interfaces.',
    salary: 'KES 110,000',
    endDate: '2024-09-30',
    applicants: [
      { id: 1, name: 'Alice Johnson', email: 'alice@example.com', cv: '/documents/alice_cv.pdf', coverLetter: '/documents/alice_cover_letter.pdf' }
    ]
  },
  {
    id: 4,
    role: 'DevOps Engineer',
    level: 'Mid Level',
    requirements: ['Docker', 'Kubernetes', 'CI/CD', 'AWS'],
    description: 'Implement and maintain CI/CD pipelines.',
    salary: 'KES 180,000',
    endDate: '2024-10-25',
    applicants: [
      { id: 1, name: 'Michael Brown', email: 'michael@example.com', cv: '/documents/michael_cv.pdf', coverLetter: '/documents/michael_cover_letter.pdf' },
      { id: 2, name: 'Emma Wilson', email: 'emma@example.com', cv: '/documents/emma_cv.pdf', coverLetter: '/documents/emma_cover_letter.pdf' }
    ]
  },
  {
    id: 5,
    role: 'Data Scientist',
    level: 'Senior',
    requirements: ['Python', 'SQL', 'Machine Learning', 'Pandas'],
    description: 'Analyze data to extract insights and build models.',
    salary: 'KES 200,000',
    endDate: '2024-12-01',
    applicants: []
  },
  {
    id: 6,
    role: 'Mobile Developer',
    level: 'Mid Level',
    requirements: ['Flutter', 'Dart', 'Firebase'],
    description: 'Develop cross-platform mobile applications.',
    salary: 'KES 130,000',
    endDate: '2024-11-10',
    applicants: [
      { id: 1, name: 'Robert White', email: 'robert@example.com', cv: '/documents/robert_cv.pdf', coverLetter: '/documents/robert_cover_letter.pdf' }
    ]
  },
  {
    id: 7,
    role: 'Fullstack Developer',
    level: 'Mid Level',
    requirements: ['React', 'Node.js', 'MongoDB'],
    description: 'Develop end-to-end web applications.',
    salary: 'KES 170,000',
    endDate: '2024-10-20',
    applicants: [
      { id: 1, name: 'Grace Taylor', email: 'grace@example.com', cv: '/documents/grace_cv.pdf', coverLetter: '/documents/grace_cover_letter.pdf' }
    ]
  },
  {
    id: 8,
    role: 'Project Manager',
    level: 'Senior',
    requirements: ['Agile', 'Scrum', 'Leadership'],
    description: 'Manage software development projects and teams.',
    salary: 'KES 220,000',
    endDate: '2024-09-28',
    applicants: []
  },
  {
    id: 9,
    role: 'Marketing Specialist',
    level: 'Associate',
    requirements: ['SEO', 'Content Marketing', 'Social Media'],
    description: 'Drive traffic and engagement through digital marketing.',
    salary: 'KES 100,000',
    endDate: '2024-10-05',
    applicants: [
      { id: 1, name: 'Laura King', email: 'laura@example.com', cv: '/documents/laura_cv.pdf', coverLetter: '/documents/laura_cover_letter.pdf' }
    ]
  },
  {
    id: 10,
    role: 'HR Manager',
    level: 'Senior',
    requirements: ['HR Management', 'Recruiting', 'Conflict Resolution'],
    description: 'Lead the HR department and recruitment processes.',
    salary: 'KES 150,000',
    endDate: '2024-11-15',
    applicants: []
  },
  {
    id: 11,
    role: 'Software Tester',
    level: 'Junior',
    requirements: ['Selenium', 'JIRA', 'Test Automation'],
    description: 'Test and ensure the quality of software products.',
    salary: 'KES 90,000',
    endDate: '2024-11-05',
    applicants: [
      { id: 1, name: 'Chris Green', email: 'chris@example.com', cv: '/documents/chris_cv.pdf', coverLetter: '/documents/chris_cover_letter.pdf' }
    ]
  },
  {
    id: 12,
    role: 'Product Manager',
    level: 'Senior',
    requirements: ['Product Roadmaps', 'User Stories', 'Market Research'],
    description: 'Define the vision and roadmap for product development.',
    salary: 'KES 250,000',
    endDate: '2024-12-31',
    applicants: []
  },
  {
    id: 13,
    role: 'Content Writer',
    level: 'Entry Level',
    requirements: ['SEO Writing', 'Blogging', 'Copywriting'],
    description: 'Write and edit content for our website and blog.',
    salary: 'KES 60,000',
    endDate: '2024-10-18',
    applicants: []
  },
  {
    id: 14,
    role: 'Security Analyst',
    level: 'Mid Level',
    requirements: ['Penetration Testing', 'Risk Assessment', 'Cybersecurity'],
    description: 'Monitor and secure the company’s IT infrastructure.',
    salary: 'KES 190,000',
    endDate: '2024-11-20',
    applicants: []
  },
  {
    id: 15,
    role: 'Business Analyst',
    level: 'Mid Level',
    requirements: ['SQL', 'Data Analysis', 'Business Intelligence'],
    description: 'Analyze business requirements and data trends.',
    salary: 'KES 140,000',
    endDate: '2024-10-30',
    applicants: []
  },
  {
    id: 16,
    role: 'Graphic Designer',
    level: 'Entry Level',
    requirements: ['Adobe Photoshop', 'Illustrator', 'Creativity'],
    description: 'Create visual assets for branding and marketing.',
    salary: 'KES 80,000',
    endDate: '2024-12-10',
    applicants: [
      { id: 1, name: 'Anna Hall', email: 'anna@example.com', cv: '/documents/anna_cv.pdf', coverLetter: '/documents/anna_cover_letter.pdf' }
    ]
  },
  {
    id: 17,
    role: 'Database Administrator',
    level: 'Mid Level',
    requirements: ['SQL', 'Database Optimization', 'PostgreSQL'],
    description: 'Manage and optimize company databases.',
    salary: 'KES 170,000',
    endDate: '2024-10-25',
    applicants: []
  },
  {
    id: 18,
    role: 'QA Engineer',
    level: 'Mid Level',
    requirements: ['Manual Testing', 'Test Automation', 'Agile'],
    description: 'Ensure software quality through rigorous testing.',
    salary: 'KES 120,000',
    endDate: '2024-11-30',
    applicants: [
      { id: 1, name: 'David Martinez', email: 'david@example.com', cv: '/documents/david_cv.pdf', coverLetter: '/documents/david_cover_letter.pdf' }
    ]
  },
  {
    id: 19,
    role: 'IT Support Specialist',
    level: 'Entry Level',
    requirements: ['Troubleshooting', 'Networking', 'Customer Support'],
    description: 'Provide IT support to internal and external users.',
    salary: 'KES 70,000',
    endDate: '2024-12-15',
    applicants: []
  },
  {
    id: 20,
    role: 'Sales Manager',
    level: 'Senior',
    requirements: ['Sales', 'CRM', 'Negotiation'],
    description: 'Manage the sales team and drive revenue growth.',
    salary: 'KES 160,000',
    endDate: '2024-10-10',
    applicants: []
  }
];

  
  
