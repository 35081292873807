import { Box } from '@mui/material';
import BlogPostList from 'components/dashboard/Blog/BlogPostList';
import BlogPostGrid from 'components/website/blog/BlogPostGrid';
import { shades } from 'theme';
import React from 'react';

const Main = ({ blogs }) => {
  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
        textAlign: 'center',
      }}
    >
      {/* <BlogPostList posts={blogs} />  */}
      <BlogPostGrid posts={blogs} />
    </Box>
  );
};

export default Main;
