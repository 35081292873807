import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

const OurApproach = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: '4rem 2rem',
        maxWidth: '1200px',
        margin: 'auto',
        lineHeight: 1.6,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '2rem',
          color: theme.palette.primary.main,
        }}
      >
        Our Approach
      </Typography>

      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
        We take pride in being a reliable partner to entrepreneurs.
      </Typography>
      <Typography variant="body1" paragraph>
        Our Tribe of Tools strategy addresses entrepreneurial challenges with practical, automated solutions, building a strong support system for every stage of your journey.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          marginTop: '3rem',
          marginBottom: '1rem',
          color: theme.palette.secondary.main,
        }}
      >
        Why Entrepreneurial Legal Partner?
      </Typography>
      <Typography variant="body1" paragraph>
        Our name—Entrepreneurial Legal Partner—reflects our commitment to being more than just a service provider. We aspire to be the steadfast ally in your entrepreneurial journey, offering the guidance and tools needed to succeed.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          marginTop: '3rem',
          marginBottom: '1rem',
          color: theme.palette.secondary.main,
        }}
      >
        Partnerships & Collaboration
      </Typography>
      <Typography variant="body1">
        We’re open to partnerships and discussions and look forward to supporting your entrepreneurial success. Whether you're at the beginning of your journey or looking to expand, we're here to help you navigate the complexities of entrepreneurship with confidence and security.
      </Typography>
    </Box>
  );
};

export default OurApproach;
