import React, { useState } from 'react';
import Hero from './Hero';
import Main from './Main';
import { blogPosts } from 'data/data'; // Assuming this contains the blog post data

const Blogs = () => {
  // State to manage filtered blog posts
  const [filteredBlogs, setFilteredBlogs] = useState(blogPosts);

  return (
    <>
      {/* Pass blogPosts and setFilteredBlogs to Hero for searching */}
      <Hero blogs={blogPosts} setFilteredBlogs={setFilteredBlogs} />
      
      {/* Pass the filtered blogs to Main to display */}
      <Main blogs={filteredBlogs} />
    </>
  );
};

export default Blogs;
