import { useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import JobGrid from 'components/website/Job/JobGrid';
import React, { useState, useEffect } from 'react';
import { shades } from 'theme';

const Main = ({ jobs }) => {
  const theme = useTheme();    
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchQuery, setSearchQuery] = useState('');  
  const [jobLevel, setJobLevel] = useState('all'); // For the job level dropdown
  const [dateFilter, setDateFilter] = useState('all');
  const [filteredJobs, setFilteredJobs] = useState(jobs);

  // This will be called every time jobs, jobLevel, dateFilter, or searchQuery changes
  useEffect(() => {
    filterJobs(searchQuery, jobLevel, dateFilter);
  }, [jobs, searchQuery, jobLevel, dateFilter]);

  const handleLevelChange = (e) => {
    setJobLevel(e.target.value);
  };

  const handleDateChange = (e) => {
    setDateFilter(e.target.value);
  };

  const filterJobs = (query, level, date) => {
    const filtered = jobs.filter((job) => {
      // Ensure job.title and job.level exist before using toLowerCase()
      const jobTitle = job.title ? job.title.toLowerCase() : '';
      const jobLevel = job.level ? job.level.toLowerCase() : '';
  
      // Filter by search query (title or level)
      const matchesQuery = jobTitle.includes(query.toLowerCase()) || jobLevel.includes(query.toLowerCase());
  
      // Filter by job level
      const matchesLevel = level === 'all' || jobLevel === level.toLowerCase();
  
      // Filter by date (past week, past month, or all)
      const currentDate = new Date();
      const jobDate = new Date(job.createdDate);
      let matchesDate = true;
      if (date === 'past week') {
        matchesDate = (currentDate - jobDate) / (1000 * 60 * 60 * 24) <= 7;
      } else if (date === 'past month') {
        matchesDate = (currentDate - jobDate) / (1000 * 60 * 60 * 24) <= 30;
      }

      return matchesQuery && matchesLevel && matchesDate;
    });

    setFilteredJobs(filtered); // Update the filtered jobs
  };

  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
        textAlign: 'center',
      }}
    >
      {/* Job Level Dropdown */}
      <FormControl sx={{ minWidth: isMobile ? '80%' : '20%', backgroundColor: 'white', borderRadius: '5px', margin: '1rem' }}>
        <InputLabel shrink>Job Level</InputLabel>
        <Select value={jobLevel} onChange={handleLevelChange} label="Job Level">
          <MenuItem value="all">All Levels</MenuItem>
          <MenuItem value="internship">Internship</MenuItem>
          <MenuItem value="entry level">Entry Level</MenuItem>
          <MenuItem value="mid level">Mid Level</MenuItem>
          <MenuItem value="senior level">Senior Level</MenuItem>
          <MenuItem value="management">Management</MenuItem>
        </Select>
      </FormControl>

      {/* Date Filter Dropdown */}
      <FormControl sx={{ minWidth: isMobile ? '80%' : '20%', backgroundColor: 'white', borderRadius: '5px', margin: '1rem' }}>
        <InputLabel margin="dense">Date Posted</InputLabel>
        <Select value={dateFilter} onChange={handleDateChange} label="Date Posted">
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="past week">Past Week</MenuItem>
          <MenuItem value="past month">Past Month</MenuItem>
        </Select>
      </FormControl>

      {/* Pass filteredJobs to JobGrid */}
      <JobGrid jobs={filteredJobs} />
    </Box>
  );
};

export default Main;
