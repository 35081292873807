import React, { useState } from 'react';
import { Box, TextField, useTheme, useMediaQuery } from '@mui/material';
import about from 'assets/aboutUs.jpg';

const Hero = ({ jobs, setFilteredJobs }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterJobs(query); // Call filter function whenever search changes
  };

  const filterJobs = (query) => {
    const filtered = jobs.filter((job) => {
      // Filter by search query (title or level)
      const matchesQuery = job.title.toLowerCase().includes(query) || job.level.toLowerCase().includes(query);
      return matchesQuery;
    });

    setFilteredJobs(filtered); // Update the filtered jobs
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${about})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        minHeight: isMobile ? '30vh' : '40vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '2rem',
          borderRadius: '15px',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        {/* Search Bar */}
        <TextField
          variant="outlined"
          placeholder="Search Jobs by Title or Level..."
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            backgroundColor: 'white',
            borderRadius: '5px',
            width: isMobile ? '80%' : '40%',
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
