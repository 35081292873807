import React, { useState } from 'react'
import Hero from './Hero'
import Main from './Main'
import { jobs } from 'data/data'

const Jobs = () => {
  const [filteredJobs, setFilteredJobs] = useState(jobs);
  return (
    <>
      <Hero jobs={jobs} setFilteredJobs={setFilteredJobs}/>
      <Main jobs={jobs} setFilteredJobs={setFilteredJobs}/>
      
    </>
  )
}

export default Jobs