import React from 'react';
import { Container, Box, Typography, Grid, Paper, Chip, Button} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import Hero from './Hero';

const tools = [
  {
    name: 'Business Structure Recommender',
    description: 'Get recommendations on the best structure for your business based on specific criteria.',
    route: '/Tools/business-structure-recommender',
    status: 'available', // Status can be 'available' or 'coming soon'
  },
  {
    name: 'Automatic Legal Documents Generator',
    description: 'Automatically generate documents for legal purposes based on your jurisdictional provisions.',
    route: '/other-tool',
    status: 'coming soon',
  },
  {
    name: 'AI-E Optimizer',
    description: 'Optimize AI and edge infrastructure with real-time, data-driven recommendations for sustainable development.',
    route: '/Tools/AI-E-Optimizer',
    status: 'available',
  },
];


const ToolsPage = ({ onNavigate }) => {
 

  return (
    <>
      <Hero />
      <Container maxWidth="lg" style={{ padding: '20px', marginTop: '1rem', marginBottom:'3rem' }}>
        <Box mt={4} mb={2}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            Available Tools
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {tools.map((tool, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                style={{
                  padding: '20px',
                  textAlign: 'center',
                  height: '500px', // Ensures uniform size
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <BusinessIcon color={tool.status === 'available' ? 'primary' : 'secondary'} style={{ fontSize: 50 }} />
                <Typography variant="h6" gutterBottom>
                  {tool.name}
                </Typography>
                <Typography variant="body1" paragraph>
                  {tool.description}
                </Typography>
                <Chip
                  label={tool.status === 'available' ? 'Available' : 'Coming Soon'}
                  color={tool.status === 'available' ? 'primary' : 'secondary'}
                  style={{ marginBottom: '15px' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onNavigate(tool.route)}
                  disabled={tool.status === 'coming soon'}
                >
                  {tool.status === 'available' ? 'Open Tool' : 'Coming Soon'}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
        
      </Container>
    </>
  );
};

export default ToolsPage;
