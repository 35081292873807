import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Grid, Card, CardContent, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { shades } from 'theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';
import ourService from 'assets/services.png';

const ServiceCard = ({ id, service, description }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 80 }} // Start off-screen (invisible)
      animate={{ opacity: 1, x: 0 }}   // Animate to on-screen
      transition={{ duration: 2 }} 
      style={{ height: '100%' }}  // Ensure full height
    >
      <Card sx={{ margin: '1rem', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle2" color={shades.indigo[700]}>
            {id}
          </Typography>
          <Typography variant="h6" color={shades.indigo[400]} sx={{ fontWeight: 'bold', flexGrow: 1 }}>
            {service}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
            {description}
          </Typography>
        </CardContent>
        <MuiLink
          component={Link} // Make it a Link component from react-router-dom
          to="/Services" // Use 'to' instead of 'href'
          variant="body2"
          sx={{
            margin: '1rem', // Add margin for spacing
            display: 'inline-flex', // Use inline-flex to align icon and text
            alignItems: 'center', // Center the items vertically
            color: shades.indigo[500], // Change link color
            textDecoration: 'none', // Remove underline
          }}
        >
          Learn More
          <ArrowForwardIcon sx={{ marginLeft: '0.5rem' }} /> {/* Add right arrow icon */}
        </MuiLink>
      </Card>
    </motion.div>
  );
};

const Services = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const services = [
    {
      id: '01',
      service: 'IP Management',
      description: 'We help businesses protect their intellectual property by offering comprehensive IP management services, including trademark registration, patent filing, and legal advice on IP rights.',
    },
    {
      id: '02',
      service: 'Business Registration',
      description: 'Our team assists in the seamless registration of businesses, ensuring all legal requirements are met, and helping you navigate the complexities of business law.',
    },
    {
      id: '03',
      service: 'Consultation',
      description: 'We provide expert legal consultation tailored to the needs of startups and established businesses, helping you navigate legal challenges with confidence.',
    },
    {
      id: '04',
      service: 'Legal Documents Drafting',
      description: 'Our legal experts assist in drafting essential legal documents, ensuring all your agreements are compliant and legally binding.',
    },
    {
      id: '05',
      service: 'Regulatory Compliance',
      description: 'We help businesses adhere to all relevant regulations, ensuring your operations are compliant with local and international laws.',
    },
    {
      id: '06',
      service: 'International Legal Services',
      description: 'We offer legal services to businesses operating internationally, navigating the complexities of global law to protect your interests.',
    },
    {
      id: '07',
      service: 'Cybersecurity Analysis Services',
      description: 'Our cybersecurity analysis services are designed to safeguard your business against cyber threats. We perform in-depth risk assessments, identify vulnerabilities, and provide solutions to ensure your systems and data remain secure in an increasingly digital and connected world.'
    }
  ];

  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
        textAlign: 'left',
      }}
    >
      <Typography color={shades.lighgreen} sx={{ mb: '1rem' }}>
        OUR SERVICES
      </Typography>
      <Typography variant="body1" sx={{ color: shades.black[300], mb: 4 }}>
        These are some of the services we offer to help your business succeed.
      </Typography>
      <Box 
        sx={{ 
          display: 'flex', // Set the container to flex
          flexDirection: { xs: 'column', lg: 'row' }, // Change direction based on screen size
          width: '100%',
        }}
      >
        <Box sx={{ 
          width: { xs: '100%', lg: '30%' }, // Adjust width for different screen sizes
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          padding: { xs: '1rem', lg: '0' } // Add padding for smaller screens
        }}>
          <img src={ourService} alt='service' style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Grid container spacing={2} justifyContent="center" sx={{ width: { xs: '100%', lg: '70%' } }}>
          {services.map((service) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={service.id}>
              <ServiceCard
                id={service.id}
                service={service.service}
                description={service.description}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Services;
