import React, { useState } from 'react';
import BoxHeader from 'components/dashboard/Box/BoxHeader';
import DashboardBox from 'components/dashboard/Box/DashboardBox';
import FlexBetween from 'components/dashboard/Box/FlexBetween';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { shades } from 'theme';
import MyResponsiveLine from 'components/charts/ResponsiveLineChart';
import {blogPosts, blogPostStatisticsData} from 'data/data';
import BlogPostList from 'components/dashboard/Blog/BlogPostList';

const BloggerDashboard = () => {
  const blogTitle = 'Law and Business';
  const likes = 30;
  const comments = 5;
  const posts = 10;
  const { palette } = useTheme();
  const [showRecent, setShowRecent] = useState(false);

  // Function to filter recent posts
  const filterRecentPosts = (posts) => {
    // Assuming recent posts are the ones with the most recent dates
    return posts.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 5); // Show last 5 posts
  };

  const handleFilterToggle = () => {
    setShowRecent((prev) => !prev);
  };

  // Display either recent posts or all posts based on the toggle state
  const displayedPosts = showRecent ? filterRecentPosts(blogPosts) : blogPosts;

  return (
    <>
      <FlexBetween>
        <Grid container spacing={2}>
          {/* Recent Post Box */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <DashboardBox>
              <BoxHeader
                icon={
                  <ArticleIcon
                    fontSize="inherit"
                    sx={{
                      fontSize: '4rem', // Increase the icon size
                      color: '#ADD8E6', // Light blue icon
                      position: 'relative',
                      top: '-2rem', // Make it float halfway above the box
                    }}
                  />
                }
                title="Recent Post"
                subtitle={blogTitle}
                sideText=""
              />
              <FlexBetween>
                <FlexBetween sx={{ gap: 2, marginLeft: 2 }}>
                  <Typography variant="h6" sx={{ color: '#00008B' }}>
                    {/* Dark blue typography */}
                    {comments}
                  </Typography>
                  <Typography sx={{ color: '#00008B' }}>Comments</Typography>
                </FlexBetween>
                <FlexBetween sx={{ gap: 2, marginRight: 2 }}>
                  <Typography variant="h6" sx={{ color: '#00008B' }}>
                    {/* Dark blue typography */}
                    {likes}
                  </Typography>
                  <Typography sx={{ color: '#00008B' }}>Likes</Typography>
                </FlexBetween>
              </FlexBetween>
            </DashboardBox>
          </Grid>

          {/* Total Posts Box */}
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <DashboardBox>
              <BoxHeader
                icon={
                  <LibraryBooksIcon
                    fontSize="inherit"
                    sx={{
                      fontSize: '4rem', // Increase the icon size
                      color: '#800080', // Light purple icon
                      position: 'relative',
                      top: '-2rem', // Make it float halfway above the box
                    }}
                  />
                }
                title=""
                subtitle=""
                sideText=""
              />
              <Typography color={palette.grey[700]} sx={{marginBottom:2, fontSize:'1.5rem'}}>Total Posts</Typography>
              <Typography sx={{ marginLeft: 2, color: '#800080' }} variant="h6">
                {/* Dark purple typography */}
                {posts}
              </Typography>
            </DashboardBox>
          </Grid>

          {/* Total Comments Box */}
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <DashboardBox>
              <BoxHeader
                icon={
                  <CommentIcon
                    fontSize="inherit"
                    sx={{
                      fontSize: '4rem', // Increase the icon size
                      color: '#9370DB', // Light purple icon
                      position: 'relative',
                      top: '-2rem', // Make it float halfway above the box
                    }}
                  />
                }
                title=""
                subtitle=""
                sideText=""
              />
              <Typography color={palette.grey[700]} sx={{marginBottom:2, fontSize:'1.5rem'}}>Total Comments</Typography>
              <Typography
                sx={{ marginLeft: 2, color: '#9370DB' }}
                variant="h6"
              >
                {/* Dark purple typography */}
                {comments}
              </Typography>
            </DashboardBox>
          </Grid>

          {/* Total Likes Box */}
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <DashboardBox>
              <BoxHeader
                icon={
                  <FavoriteIcon
                    fontSize="inherit"
                    sx={{
                      fontSize: '4rem', // Increase the icon size
                      color: '#FFB6C1', // Light red/pink icon
                      position: 'relative',
                      top: '-2rem', // Make it float halfway above the box
                    }}
                  />
                }
                title=""
                subtitle=""
                sideText=""
              />
              <Typography color={palette.grey[700]} sx={{marginBottom:2, fontSize:'1.5rem'}}>Total Likes</Typography>
              <Typography sx={{ marginLeft: 2, color: '#FF0000' }} variant="h6">
                {/* Dark red typography */}
                {likes}
              </Typography>
            </DashboardBox>
          </Grid>
        </Grid>
      </FlexBetween>
      <FlexBetween>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardBox>
                    <BoxHeader
                    icon={
                    <ShowChartIcon
                        fontSize="inherit"
                        sx={{
                        fontSize: '4rem', // Increase the icon size
                        color: shades.darkgreen, // Light red/pink icon
                        position: 'relative',
                        top: '-2rem', // Make it float halfway above the box
                        }}
                    />
                    }
                    title=""
                    subtitle="Total posts vs Total Comments per month"
                    sideText=""
                />
                <MyResponsiveLine data={blogPostStatisticsData} dataLegend='month'/>
                </DashboardBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardBox >
                    <FlexBetween>
                    <Typography color={palette.grey[700]} variant="h6" mb="-0.1rem">
                        Recent Blogs
                    </Typography>
                    <Button variant="contained" onClick={handleFilterToggle}>
                        {showRecent ? 'Show All' : 'Show Recent'}
                    </Button>
                    </FlexBetween>
                    <Box sx={{ maxHeight: '450px', overflowY: 'auto' }}>
                      <BlogPostList posts={displayedPosts} />
                    </Box>                    
                </DashboardBox>
                </Grid>
        </Grid>
      </FlexBetween>
    </>
  );
};

export default BloggerDashboard;
