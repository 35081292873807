import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  MenuItem,
  Button,
  Typography,
  Select,
  FormControl,
  Box,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import countries from 'components/website/Tools/countries.json'; // Import the JSON file
import businessStructures from 'components/website/Tools/businessStructures'; // Import the JavaScript module
import { recommendStructure } from 'components/website/Tools/utils'; // Import the utility function
import business from 'assets/business.jpeg'
import logo from 'assets/ELP.png'
import { shades } from 'theme';

const BusinessStructureRecommender = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [currentStep, setCurrentStep] = useState(0);
  const [country, setCountry] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [owners, setOwners] = useState('');
  const [intentionToMakeProfits, setIntentionToMakeProfits] = useState('');
  const [management, setManagement] = useState('');
  const [funding, setFunding] = useState('');
  const [liability, setLiability] = useState('');
  const [profitSharing, setProfitSharing] = useState('');
  const [sellBondsShares, setSellBondsShares] = useState('');
  const [loading, setLoading] = useState(false);

  const [showManagementSection, setShowManagementSection] = useState(false);
  const [showProfitSharingSection, setShowProfitSharingSection] = useState(false);
  const [showFundingSection, setShowFundingSection] = useState(false);
  const [showSellBondsSharesSection, setShowSellBondsSharesSection] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout duration as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  const managementOptions = {
    'Only one individual will own and operate the business.': [
      'The business will be managed solely by the owner.',
      'Management will be handled by a board of directors or elected members.'
    ],
    'More than one individual will own and operate the business.': [
      'Management will be distributed equally among the partners.',
      'Management will be distributed among the partners based on specific agreements.',
      'Management will be done democratically by all members.',
      'Management will be handled by a board of directors or elected members.'
    ]
  };

  const profitSharingOptions = {
    'Only one individual will own and operate the business.': [
      'The sole owner will retain all profits.',
      'Profits will be distributed based on the usage of services by members.'
    ],
    'More than one individual will own and operate the business.': [
      'Profits will be shared equally among multiple owners.',
      'Profits will be distributed based on shares owned by the shareholders.',
      'Profits will be shared according to the agreement between partners.',
      'Profits will be distributed based on the usage of services by members.'
    ]
  };

  const handleRecommendation = () => {
    setLoading(true);
    setTimeout(() => {
      const bestStructure = recommendStructure(
        businessStructures,
        intentionToMakeProfits,
        owners,
        liability,
        management,
        profitSharing,
        funding,
        sellBondsShares
      );
  
      // Convert the recommendation object to a string
      const recommendationString = bestStructure
        ? `${bestStructure.name}. Major Advantage:\n${bestStructure.majorAdvantage}. Major Disadvantage: ${bestStructure.majorDisadvantage}.`
        : 'No recommendation available.';
  
      setLoading(false);
      navigate('/Tools/business-structure-recommender/recommendation', {
        state: { 
          recommendation: recommendationString, 
          country // Pass the country data here
        }
      });
    }, 3000);
  };

  const steps = [
    {
      id: 'country',
      component: (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>Which country are you from?</Typography>
          <Select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            label="Country"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            {countries.map((c, index) => (
              <MenuItem key={index} value={c}>{c}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    {
      id: 'businessType',
      component: (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>What type of business do you run?</Typography>
          <TextField
            label="Type of Business (max 5 words)"
            variant="outlined"
            value={businessType}
            onChange={(e) => {
              const input = e.target.value;
              const words = input.trim().split(/\s+/);
              if (words.length <= 5) {
                setBusinessType(input);
              }
            }}
            placeholder="e.g., law firm, independent contractor, freelancer, investment SACCO"
            fullWidth
            sx={{
              input: { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiInputLabel-root': { color: 'white' },
            }}
          />
        </FormControl>
      )
    },
    {
      id: 'owners',
      component: (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>What is the ownership structure?</Typography>
          <Select
            value={owners}
            onChange={(e) => {
              const selectedOwners = e.target.value;
              setOwners(selectedOwners);
              setShowManagementSection(true);
              setShowProfitSharingSection(
                intentionToMakeProfits === 'Yes' &&
                selectedOwners !== 'Only one individual will own and operate the business.'
              );
              setCurrentStep(3); // Move to the next step
            }}
            label="Ownership Structure"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            <MenuItem value="Only one individual will own and operate the business.">Only one individual will own and operate the business.</MenuItem>
            <MenuItem value="More than one individual will own and operate the business.">More than one individual will own and operate the business.</MenuItem>
          </Select>
        </FormControl>
      )
    },
    {
      id: 'intentionToMakeProfits',
      component: (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>Does your business aim to generate profits for its owners or stakeholders?</Typography>
          <Select
            value={intentionToMakeProfits}
            onChange={(e) => {
              const selectedIntention = e.target.value;
              setIntentionToMakeProfits(selectedIntention);
              setShowFundingSection(selectedIntention === 'Yes');
              setShowProfitSharingSection(selectedIntention === 'Yes' && owners !== 'Only one individual will own and operate the business.');
              setShowSellBondsSharesSection(selectedIntention === 'Yes');
              setCurrentStep(4); // Move to the next step
            }}
            label="Does your business aim to generate profits for its owners or stakeholders?"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      )
    },
    {
      id: 'management',
      component: showManagementSection && (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>Who will manage the business?</Typography>
          <Select
            value={management}
            onChange={(e) => setManagement(e.target.value)}
            label="Who will manage the business?"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            {managementOptions[owners]?.map((option, index) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    {
      id: 'funding',
      component: showFundingSection && (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>How do you intend to fund your business?</Typography>
          <Select
            value={funding}
            onChange={(e) => setFunding(e.target.value)}
            label="How do you intend to fund your business?"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            <MenuItem value="Funding will come from personal savings.">Funding will come from personal savings.</MenuItem>
            <MenuItem value="Funding will come from contributions made by the business partners or members.">Funding will come from contributions made by the business partners or members.</MenuItem>
            <MenuItem value="Funding will be acquired through a combination of member contributions and loans.">Funding will be acquired through a combination of member contributions and loans.</MenuItem>
            <MenuItem value="Funding will come from the sale of stocks or bonds.">Funding will come from the sale of stocks or bonds.</MenuItem>
          </Select>
        </FormControl>
      )
    },
    {
      id: 'liability',
      component: (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>What is your preferred level of liability for the business?</Typography>
          <Select
            value={liability}
            onChange={(e) => setLiability(e.target.value)}
            label="Preferred level of liability"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            <MenuItem value="Limited liability">Limited liability</MenuItem>
            <MenuItem value="Unlimited liability">Unlimited liability</MenuItem>
          </Select>
        </FormControl>
      )
    },
    {
      id: 'profitSharing',
      component: showProfitSharingSection && (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>How will profits be shared among the business owners or stakeholders?</Typography>
          <Select
            value={profitSharing}
            onChange={(e) => setProfitSharing(e.target.value)}
            label="How will profits be shared?"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            {profitSharingOptions[owners]?.map((option, index) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    {
      id: 'sellBondsShares',
      component: showSellBondsSharesSection && (
        <FormControl fullWidth variant="standard" margin="normal">
          <Typography sx={{ marginBottom: '2rem' }}>Will the business sell bonds or shares to raise funds?</Typography>
          <Select
            value={sellBondsShares}
            onChange={(e) => setSellBondsShares(e.target.value)}
            label="Sell bonds or shares"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiSelect-icon': { color: 'white' },
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      )
    },
    {
      id: 'review',
      component: (
        <Container>
          <Typography variant="h5" gutterBottom>
            Review your choices and get a recommendation
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRecommendation}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Get Recommendation'}
          </Button>
        </Container>
      )
    }
  ];

  return (
    <Box
      sx={{
        backgroundImage: `url(${business})`,
        backgroundColor: 'rgba(0, 0, 0)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        justifyContent:'center',
        alignItems:'center',
        minHeight: isMobile ? '100vh' : '100vh', // Adjust the minimum height as needed
        position: 'relative',
      }}
    >
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: 'transparent',
          boxShadow:'none'
        }}
      >
        <Toolbar sx={{ margin: { xs: '0.5rem 3rem', lg: '0.5rem 5rem' } }}>
          <img
              src={logo}
              alt="company logo"
              style={{ height: '40px', width: '40px', marginRight: '2rem', borderRadius: '50%' }}
            />
          <Typography variant="h6" component="div">
            {isMobile ? 'ELP' : 'Entrepreneurial Legal Partner'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ marginTop: 40, marginBottom: 30 }}>
        {steps[currentStep]?.component}
        <Box sx={{ marginTop: 2 }}>
          {currentStep > 0 && (
            <Button
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white', marginRight: 1 }}
              onClick={() => setCurrentStep((prevStep) => Math.max(prevStep - 1, 0))}
            >
              Back
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              variant="contained"
              sx={{ 
                backgroundColor: 'white',
                color: 'black',
                '&:hover':{
                  backgroundColor: shades.darkgreen,
                  color:'white'
                }
               }}
              onClick={() => setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1))}
            >
              Next
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default BusinessStructureRecommender;
