import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { shades } from 'theme'
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import EmailIcon from '@mui/icons-material/Email';

const Section = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
        <Box
        sx={{
            backgroundColor:shades.lighgreen,
            height: '20vh',
        }}
        />
         <Box
        sx={{
            backgroundColor: shades.white[500],
            padding: { xs: '1.5rem', sm: '2rem', md: '4rem 8rem', lg: '4rem 15rem' },
            textAlign: 'center',
        }}
        >
            <Box margin='2rem 0' position='absolute' top={isMobile?'24rem':'36rem'} zIndex={1} width='80%'>
                <Grid container spacing={2} justifyContent="center"> {/* Center grid items */}
                    <Grid item xs={12} md={6}>
                        <Box 
                            margin='1.5rem' 
                            display="flex" 
                            flexDirection="column" 
                            alignItems="center" 
                            sx={{ 
                                backgroundColor: 'white', 
                                height: '100px', // Set a fixed height for uniformity
                                width: '100%', // Set full width
                                boxShadow: 3, // Add shadow for depth
                                borderRadius: '4px' // Optional: add rounded corners
                            }}
                        >                            
                            <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
                                <IconButton sx={{ fontSize: '2rem' }}> {/* Increased icon size */}
                                    <CallRoundedIcon fontSize="inherit" /> {/* Ensures the icon inherits the size */}
                                </IconButton>
                                <Box ml={isMobile ? 0 : 2} textAlign={isMobile ? 'center' : 'left'}>                                    
                                    <Typography >123-456-7890</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box 
                            margin='1.5rem' 
                            display="flex" 
                            flexDirection="column" 
                            alignItems="center" 
                            sx={{ 
                                backgroundColor: 'white', 
                                height: '100px', // Set a fixed height for uniformity
                                width: '100%', // Set full width
                                boxShadow: 3, // Add shadow for depth
                                borderRadius: '4px' // Optional: add rounded corners
                            }}
                        >
                            
                            <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
                                <IconButton sx={{ fontSize: '2rem' }}> {/* Increased icon size */}
                                    <EmailIcon fontSize="inherit" /> {/* Ensures the icon inherits the size */}
                                </IconButton>
                                <Box ml={isMobile ? 0 : 2} textAlign={isMobile ? 'center' : 'left'}>
                                   
                                    <Typography  >entr.legal.partner@gmail.com</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </>
  )
}

export default Section