import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Paper } from '@mui/material';

const MyResponsiveLine = ({ data, dataLegend }) => (
    <Paper style={{ height: 400 }}>
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            curve='basis'
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            lineWidth={3}
            enablePoints={false}
            pointSize={12}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={4}
            pointBorderColor={{ from: 'serieColor' }}
            enablePointLabel={true}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableArea={true}
            areaOpacity={0.3} // Adjust the opacity of the area
            areaColor={{ from: 'color', modifiers: [['darker', 1.0]] }} // Adjust the area color
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 100,
                    translateY: -33,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    </Paper>
);

export default MyResponsiveLine;
