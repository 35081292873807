import * as React from 'react';
import { Box, Typography, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { shades } from 'theme';
import people from 'assets/money-smiling.png';

export default function Hero() {
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    const img = new Image();
    img.src = people;
    img.onload = () => setLoading(false);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',  // Full container width
        height: isMobile ? '30vh' : '50vh',  // Adjust height for mobile and desktop
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
         // Adjust padding for larger screens
        alignItems: 'center',
        background: `linear-gradient(90deg, ${shades.black[500]}, ${shades.black[200]})`,
        marginTop:'5rem',
        padding: { xs: '0rem', sm: '0rem', md: '0rem 8rem', lg: '0rem 15rem' },
        
      }}
    >
      {/* Skeleton loading effect */}
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"  // Ensure the skeleton uses full height
          sx={{ backgroundColor: shades.black[100] }}  // Adjust the skeleton background color
        />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${people})`,
            backgroundSize: 'contain',  // Contain image on small screens, cover on larger screens
            backgroundPosition: isMobile ? 'center' : 'left',  // Image stays on the left on larger screens
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',  // Ensure the image also takes full height
            
          }}
        >
          {/* Content Box */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '60%',  // Use half of the screen for text on larger screens
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center', // Vertically align text to the center
              textAlign: 'right',  // Align text to the right
              paddingRight: isMobile ? '1.5rem' : '3rem', // Adjust padding for text on larger screens
            }}
          >
            <Typography 
              variant={isMobile ? 'h4' : 'h2'}  // Smaller text on mobile
              sx={{ 
                mb: 2, 
                color: shades.white[500], 
                fontWeight: 'bold',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                margin: isMobile? '0rem': '0 5rem'
              }}
            >
              Starting a new business or do you need legal business advice?
            </Typography>
            <Typography 
              variant={isMobile ? 'h6' : 'h5'} 
              sx={{ 
                color: shades.lighgreen, 
                mb: 3,                 
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                margin: isMobile? '0rem': '0 5rem'
              }}
            >
              Let's talk
            </Typography>            
          </Box>
        </Box>
      )}
    </Box>
  );
}
