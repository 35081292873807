import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { shades } from '../../../theme.js';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      sx={{
        backgroundColor: shades.indigo[700],
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem', // Adds spacing between the elements
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: '2rem' }}>
        ELP
      </Typography>

      <Grid container spacing={2} justifyContent="center" sx={{marginBottom:'2rem'}}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">entr.legal.partner@gmail.com</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">Nairobi, Kenya</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">123-356-7890</Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography variant="body1">
            <Link href="/ELP-staff-login" color="inherit" underline="hover">
              ELP Staff
            </Link>
          </Typography>
        </Grid> */}
      </Grid>

      {/* Combine Privacy Policy and Copyright in the same Grid container */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="body1">
            <Link href="/Privacy-Policy" color="inherit" underline="hover">
              ELP Privacy Policy
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
            © {currentYear} Entrepreneurial Legal Partner. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
