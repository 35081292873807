import React from 'react';
import { Box, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { jobs } from 'data/data'; // Import the job data

const JobList = () => {
  const navigate = useNavigate();

  // Handle job click to navigate to job details
  const handleJobClick = (id) => {
    navigate(`/Dashboard/Job-Applications/${id}`);
  };

  // Handle edit job
  const handleEditJob = (id) => {
    console.log(`Edit job with id: ${id}`);
    // Add your edit logic here
  };

  // Handle delete job
  const handleDeleteJob = (id) => {
    console.log(`Delete job with id: ${id}`);
    // Add your delete logic here
  };

  return (
    <Box p={2}>     
      
      <List>
        {jobs.map((job) => (
          <ListItem 
            key={job.id} 
            button 
            onClick={() => handleJobClick(job.id)} 
            sx={{ 
              borderBottom: '1px solid #ddd', 
              '&:hover': { backgroundColor: '#f5f5f5' } 
            }}
          >
            <ListItemText 
              primary={job.role} 
              secondary={`End Date: ${job.endDate} | Applicants: ${job.applicants.length}`} 
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={(e) => { e.stopPropagation(); handleEditJob(job.id); }}>
                <Edit />
              </IconButton>
              <IconButton edge="end" onClick={(e) => { e.stopPropagation(); handleDeleteJob(job.id); }}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default JobList;
