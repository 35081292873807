import React from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { jobs } from 'data/data'; // Import the job data
import JobApplicantsList from './JobApplicantsList'; // Import the new JobApplicantsList component
import DashboardBox from '../Box/DashboardBox';

const JobDetails = () => {
  const { id } = useParams(); // Get job ID from URL
  const job = jobs.find((job) => job.id === parseInt(id));

  if (!job) {
    return <Typography variant="h6">Job not found</Typography>;
  }

  return (
    <DashboardBox p={2}>
      <Typography variant="h4" gutterBottom>
        {job.role}
      </Typography>
      <Typography variant="body1">
        <strong>Description:</strong> {job.description}
      </Typography>
      <Typography variant="body1">
        <strong>Requirements:</strong> {job.requirements.join(', ')}
      </Typography>
      <Typography variant="body1">
        <strong>Salary:</strong> {job.salary}
      </Typography>
      <Typography variant="body1">
        <strong>End Date:</strong> {job.endDate}
      </Typography>

      <Typography variant="h5" mt={3}>
        Applicants
      </Typography>
      
      {/* Use the JobApplicantsList component here */}
      
        <JobApplicantsList applicants={job.applicants} jobId={job.id} />

    </DashboardBox>
  );
};

export default JobDetails;
